import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RespostaModal } from './resposta-modal.model';

@Injectable()
export class RespostaModalObservable extends Subject<RespostaModal> {

  constructor() {
    super();
  }
}
