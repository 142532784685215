import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { AutenticacaoPageComponent } from './autenticacao/autenticacao-page/autenticacao-page.component';
import { HomePageComponent } from './home/home-page/home-page.component';
import { AuthGuardService } from './autenticacao/autenticacao-guard';
import { ParaAprovarPageComponent } from './para-aprovar/para-aprovar-page/para-aprovar-page.component';
import { ReprovadosPageComponent } from './reprovados/reprovados-page/reprovados-page.component';
import { LinkPageComponent } from './link-expirado/link-page.component';

const routes: Routes = [
  { path: 'auth', component: AutenticacaoPageComponent },
  {
    path: 'link', component: LinkPageComponent,
    canActivate: [AuthGuardService],
    canLoad: [AuthGuardService],
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'auth', pathMatch: 'full' },
      { path: 'auth', component: AutenticacaoPageComponent },
      {
        path: 'home', component: HomePageComponent,
        canActivate: [AuthGuardService],
        canLoad: [AuthGuardService],
      },
      {
        path: 'para-aprovar', component: ParaAprovarPageComponent,
        canActivate: [AuthGuardService],
        canLoad: [AuthGuardService],
      },
      {
        path: 'reprovadas', component: ReprovadosPageComponent,
        canActivate: [AuthGuardService],
        canLoad: [AuthGuardService],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
