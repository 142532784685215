import { Component, OnInit } from '@angular/core';
import { StatusAprovacaoEnum } from 'src/app/shared/models/enums/status-aprovacao.enum';
import ItensAprovacao from 'src/app/shared/models/itens-aprovacao.model';
import { NewMensagensService } from 'src/app/shared/new-mensagens/new-mensagens.service';
import { FluxoAprovacaoService } from 'src/app/shared/service/fluxo-aprovacao.service';
import { LoaderService } from '../../shared/loader/loader.service';
@Component({
  selector: 'app-reprovados-page',
  templateUrl: './reprovados-page.component.html',
  styleUrls: ['./reprovados-page.component.scss'],
})
export class ReprovadosPageComponent implements OnInit {
  itensAprovacao = new ItensAprovacao();
  statusAprovacao = null;
  qtde = 0;

  constructor(
    private fluxoAprovacaoService: FluxoAprovacaoService,
    private loaderService: LoaderService,
    private mensagensService: NewMensagensService
  ) {}

  ngOnInit() {
    this.statusAprovacao = StatusAprovacaoEnum.REPROVADO_NVL2;
    this.listByStatusAprovacao();
  }

  async listByStatusAprovacao(): Promise<void> {
    try {
      this.loaderService.start();
      this.mensagensService.limparMensagens();
      this.itensAprovacao =
        await this.fluxoAprovacaoService.listByStatusAprovacao(
          StatusAprovacaoEnum.REPROVADO_NVL2
        );
      if (this.itensAprovacao && this.itensAprovacao.calculos) {
        this.qtde = this.itensAprovacao.calculos.length;
      }
      if (this.itensAprovacao && this.itensAprovacao.campanhas) {
        this.qtde = this.qtde + this.itensAprovacao.campanhas.length;
      }
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }
}
