<ul class="mensagens ">
  <li
    *ngFor="let mensagem of mensagensService.mensagens | async"
    class="message-text fade-in-fwd "
    role="alert"
    [class.is-success]="mensagem.tipo === MensagemTipo.SUCESSO"
    [class.is-error]="mensagem.tipo === MensagemTipo.ERRO"
    [class.is-warning]="mensagem.tipo === MensagemTipo.AVISO"
  >
    <div>
      <p>
        <span *ngIf="mensagem.tipo === MensagemTipo.SUCESSO">Sucesso!</span>
        <span *ngIf="mensagem.tipo === MensagemTipo.AVISO">Atenção!</span>
        <span *ngIf="mensagem.tipo === MensagemTipo.ERRO">Erro!</span>
        <span class="mensagemConteudo">{{mensagem.texto}}</span>
      </p>
    </div>
    <span
      class="close"
      (click)="closeMessage()"
    >

    </span>
  </li>
</ul>
