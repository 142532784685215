import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Mensagem from './mensagem-model';
import { MensagemTipo } from './mensagem-tipo.enum';

@Injectable({
  providedIn: 'root'
})
export class MensagensService {

  mensagens = new BehaviorSubject<Mensagem[]>([]);

  limparMensagens(): void {
    this.mensagens.next([]);
  }

  sucesso(mensagens: string[] = []): void {
    this.mensagens.next(mensagens.map(mensagem => {
      return { texto: mensagem, tipo: MensagemTipo.SUCESSO } as Mensagem;
    }));
    window.scroll(0, 0);
    setTimeout(() => {
      this.limparMensagens();
    }, 10000);
  }

  erro(mensagens: string[] = []): void {
    this.mensagens.next(mensagens.map(mensagem => {
      return { texto: mensagem, tipo: MensagemTipo.ERRO } as Mensagem;
    }));
    window.scroll(0, 0);
    setTimeout(() => {
      this.limparMensagens();
    }, 10000);
  }

  aviso(mensagens: string[] = []): void {
    this.mensagens.next(mensagens.map(mensagem => {
      return { texto: mensagem, tipo: MensagemTipo.AVISO } as Mensagem;
    }));
    window.scroll(0, 0);
    setTimeout(() => {
      this.limparMensagens();
    }, 10000);
  }

}
