import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import Mensagem from '../../mensagens/mensagem-model';
import { MensagemTipo } from '../../mensagens/mensagem-tipo.enum';
import { ConfiguracaoModalExportacao } from './modal-exportacao.model';
import { ModalExportacaoObservable } from './modal-exportacao.observable';
import { ModalExportacaoService } from './modal-exportacao.service';

@Component({
  selector: 'app-modal-exportacao',
  templateUrl: './modal-exportacao.component.html',
  styleUrls: ['./modal-exportacao.component.scss'],
  providers: [ModalExportacaoService],
})
export class ModalExportacaoComponent implements OnInit, OnDestroy {
  configuracaoModal = new ConfiguracaoModalExportacao();

  subscriptions = new Subscription();

  mensagem = new Mensagem();

  MensagemTipo = MensagemTipo;
  currentTop: number;

  constructor(
    private $modalExportacao: ModalExportacaoObservable,
    private service: ModalExportacaoService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.$modalExportacao.subscribe((configuracao) => {
        if (configuracao) {
          this.configuracaoModal = configuracao;
          this.show();
        }
      })
    );
  }

  async handleExportPDF(): Promise<void> {
    try {
      this.mensagem = new Mensagem();
      this.loaderService.start();
      const tipo = await this.service.exportarPDF(
        this.configuracaoModal.tipoRelatorio.toString(),
        this.configuracaoModal.url,
        this.configuracaoModal.filtro
      );
      if (tipo === MensagemTipo.SUCESSO) {
        this.hide();
      } else {
        this.mensagem.tipo = MensagemTipo.ERRO;
        this.mensagem.texto = 'Ocorreu erro ao exportar o arquivo para PDF.';
      }
    } catch (response) {
      if (response.status === 422) {
        this.mensagem.tipo = MensagemTipo.AVISO;
        this.mensagem.texto = response.error?.message;
      } else {
        this.mensagem.tipo = MensagemTipo.ERRO;
        this.mensagem.texto = response.error?.message;
      }
    } finally {
      this.loaderService.stop();
    }
  }

  async handleExportCSV(): Promise<void> {
    try {
      this.mensagem = new Mensagem();
      this.loaderService.start();
      const tipo = await this.service.exportarCsv(
        this.configuracaoModal.tipoRelatorio.toString(),
        this.configuracaoModal.url,
        this.configuracaoModal.filtro
      );
      if (tipo === MensagemTipo.SUCESSO) {
        this.hide();
      } else {
        this.mensagem.tipo = MensagemTipo.ERRO;
        this.mensagem.texto = 'Ocorreu erro ao exportar o arquivo para CSV.';
      }
    } catch (response) {
      if (response.status === 422) {
        this.mensagem.tipo = MensagemTipo.AVISO;
        this.mensagem.texto = response.error?.message;
      } else {
        this.mensagem.tipo = MensagemTipo.ERRO;
        this.mensagem.texto = response.error?.message;
      }
    } finally {
      this.loaderService.stop();
    }
  }

  disableScrolling() {
    let x = window.scrollX;
    let y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y); };
  }

  enableScrolling() {
    window.onscroll = function () { };
  }

  show(): void {
    this.mensagem = new Mensagem();
    const modal = document.querySelector('section.modalExportacao');
    const body = document.querySelector('body');
    modal.classList.remove('hidden');
    modal.classList.add('show');
    this.currentTop = document.documentElement.scrollTop;
    console.log(document.documentElement.scrollTop);
    body.classList.add('stop-scrolling');
  }

  hide(): void {
    const modal = document.querySelector('section.modalExportacao');
    const body = document.querySelector('body');
    modal.classList.remove('show');
    modal.classList.add('hidden');
    body.classList.remove('stop-scrolling');

    console.log(document.documentElement.scrollTop);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
