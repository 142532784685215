import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FluxoAprovacaoObservable extends Subject<void> {

  constructor() {
    super();
  }
}
