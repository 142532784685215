import { Pipe, PipeTransform } from '@angular/core';
import { TipoQuantidade } from '../enums/tipo-quantidade.enum';


@Pipe({
  name: 'tipoQuantidade'
})
export class TipoQuantidadePipe implements PipeTransform {

  transform(tipo: TipoQuantidade): string {
    switch (tipo) {
      case TipoQuantidade.QUANTIDADE_META:
        return 'Quantidade por meta';
      case TipoQuantidade.QUANTIDADE_REALIZADA:
        return 'Quantidade realizada';
    }
    return '--';
  }

}
