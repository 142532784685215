import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutenticacaoModule } from './autenticacao/autenticacao.module';
import { HomeModule } from './home/home.module';
import { LinkModule } from './link-expirado/link.module';
import { ParaAprovarModule } from './para-aprovar/para-aprovar.module';
import { ReprovadosModule } from './reprovados/reprovados.module';
import { LoaderModule } from './shared/loader/loader.module';
import { MensagensModule } from './shared/mensagens/mensagens.module';
import { ModalModule } from './shared/modal/modal.module';
import { NewMensagensModule } from './shared/new-mensagens/new-mensagens.module';
import { SharedModule } from './shared/shared.module';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    LoaderModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AutenticacaoModule,
    HomeModule,
    ParaAprovarModule,
    ReprovadosModule,
    LinkModule,
    MensagensModule,
    NewMensagensModule,
    ModalModule,
  ],
  declarations: [AppComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
