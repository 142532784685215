import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MensagemTipo } from '../../mensagens/mensagem-tipo.enum';
import Arquivo from '../../models/arquivo.model';

@Injectable()
export class ModalExportacaoService {
  constructor(private http: HttpClient) {}

  async exportarPDF(
    nomeRelatorio?: string,
    url?: string,
    filtro?: any
  ): Promise<MensagemTipo> {
    try {
      let retorno = null;
      if (filtro) {
        retorno = await this.http
          .post<Arquivo>(`${url}/PDF`, filtro)
          .toPromise();
      } else {
        retorno = await this.http.get<Arquivo>(`${url}/PDF`).toPromise();
      }
      const element = document.createElement('a');
      element.setAttribute('target', '_blank');
      element.setAttribute(
        'href',
        URL.createObjectURL(
          this.base64toBlob(retorno.arquivoBase64, 'application/pdf')
        )
      );
      element.setAttribute(
        'download',
        `${nomeRelatorio}-${new Date().getTime()}.pdf`
      );
      element.click();
      return MensagemTipo.SUCESSO;
    } catch (response) {
      return MensagemTipo.ERRO;
    }
  }

  async exportarCsv(
    nomeRelatorio?: string,
    url?: string,
    filtro?: any
  ): Promise<MensagemTipo> {
    try {
      let retorno = null;
      if (filtro) {
        retorno = await this.http
          .post<Arquivo>(`${url}/CSV`, filtro)
          .toPromise();
      } else {
        retorno = await this.http.get<Arquivo>(`${url}/CSV`).toPromise();
      }
      const element = document.createElement('a');
      element.setAttribute('target', '_blank');
      element.setAttribute(
        'href',
        URL.createObjectURL(
          this.base64toBlob(retorno.arquivoBase64, 'text/csv; charset=utf-16')
        )
      );
      element.setAttribute(
        'download',
        `${nomeRelatorio}-${new Date().getTime()}.csv`
      );
      element.click();
      return MensagemTipo.SUCESSO;
    } catch (response) {
      return MensagemTipo.ERRO;
    }
  }

  private base64toBlob(base64Data, contentType?): Blob {
    const sliceSize = 1024;
    const byteCharacters = window.atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
