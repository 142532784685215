import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfiguracaoModalObservable } from './configuracao-modal.observable';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';
import { RespostaModalObservable } from './resposta-modal.observable';
import { ShowModalComponent } from './show-modal.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ModalComponent,
    ShowModalComponent
  ],
  exports: [
    ModalComponent,
    ShowModalComponent
  ],
  providers: [
    ModalService,
    ConfiguracaoModalObservable,
    RespostaModalObservable
  ]
})
export class ModalModule { }
