<div class="autenticacao">
  <div class="meio">
    <div class="content">
      <app-autenticacao-form
        [erro]="autenticacaoError"
        (login)="login($event)"
      ></app-autenticacao-form>
      <br>
    </div>
    <div class="background">
      <div class="help">
        <a>
          <img
            src="/assets/images/login/ic_help.svg"
            alt="Ajuda"
            class="ajuda"
          >
          AJUDA
        </a>
      </div>
      <div class="title">
        <div class="titulo">
          Apuração
        </div>
        <div class="subtitulo">
          de bonificação
        </div>
      </div>
    </div>
  </div>
</div>
