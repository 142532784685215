<app-breadcrumb tituloPagina="Reprovadas"></app-breadcrumb>
<app-new-mensagens></app-new-mensagens>

<div
  class="titulo"
  container-wrapper
>
  <h1>Reprovadas</h1>
  <h4>
    Você tem <span>{{ qtde }} {{ qtde > 1 ? "cálculos" : "cálculo" }}</span>
    {{ qtde > 1 ? "reprovados" : "reprovado" }}
  </h4>
</div>

<app-calculo-item-form
  [itensAprovacao]="itensAprovacao"
  [statusAprovacao]="statusAprovacao"
>
</app-calculo-item-form>
