import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Mensagem from '../mensagens/mensagem-model';
import { MensagemTipo } from '../mensagens/mensagem-tipo.enum';

@Injectable({
  providedIn: 'root',
})
export class NewMensagensService {
  mensagens = new BehaviorSubject<Mensagem[]>([]);

  limparMensagens(): void {
    this.mensagens.next([]);
  }

  sucesso(mensagens: string[] = []): void {
    this.mensagens.next(
      mensagens.map((mensagem) => {
        return { texto: mensagem, tipo: MensagemTipo.SUCESSO } as Mensagem;
      })
    );
    window.scroll(0, 0);
    this.agendarLimparMensagens();
  }

  agendarLimparMensagens(): void {
    setTimeout(() => {
      this.limparMensagens();
    }, 1000000);
  }

  erro(mensagens: string[] = []): void {
    this.mensagens.next(
      mensagens.map((mensagem) => {
        return { texto: mensagem, tipo: MensagemTipo.ERRO } as Mensagem;
      })
    );
    window.scroll(0, 0);
    this.agendarLimparMensagens();
  }

  aviso(mensagens: string[] = []): void {
    this.mensagens.next(
      mensagens.map((mensagem) => {
        return { texto: mensagem, tipo: MensagemTipo.AVISO } as Mensagem;
      })
    );
    window.scroll(0, 0);
    this.agendarLimparMensagens();
  }
}
