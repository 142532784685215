<div
  @fade
  class="spn_hol"
  *ngIf="loaderService.loading | async"
>

  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</div>

<router-outlet></router-outlet>
