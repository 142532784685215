import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CondicaoPagamentoPipe } from './condicao-pagamento.pipe';
import { CustomCurrencyMaskConfig } from './custom-currency-mask.config';
import { PrecoPipe } from './preco.pipe';
import { PrecoService } from './preco.service';

@NgModule({
  imports: [
    CommonModule,
    CurrencyMaskModule,
  ],
  declarations: [
    PrecoPipe,
    CondicaoPagamentoPipe
  ],
  providers: [
    PrecoService,
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    }
  ],
  exports: [
    PrecoPipe,
    CondicaoPagamentoPipe,
    CurrencyMaskModule
  ]
})
export class PrecoModule { }
