import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MotivoReprovacaoService {

  constructor(
    private http: HttpClient
  ) { }

  async listDisapproveReasons(): Promise<any> {
    const url = `${environment.apiAddress}/motivo-reprovacao/`;
    const retorno = await this.http.get<any>(url).toPromise();
    return retorno;
  }

}
