import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewMensagensComponent } from './new-mensagens.component';
import { NewMensagensService } from './new-mensagens.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NewMensagensComponent
  ],
  exports: [
    NewMensagensComponent
  ],
  providers: [
    NewMensagensService
  ]
})
export class NewMensagensModule { }
