
export enum TipoFile {

  PDF = 'PDF',
  DOC = 'DOC',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  DOCX = 'DOCX'
}


