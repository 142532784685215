import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkService } from './link.service';

@Component({
  selector: 'app-link-page',
  templateUrl: './link-page.component.html',
  styleUrls: ['./link-page.component.scss'],
})
export class LinkPageComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  uuid: string;
  expired = false;
  constructor(
    private route: ActivatedRoute,
    private linkService: LinkService,
  ) {
  }

  ngOnInit(): void {
    this.listenQueryParam();
  }

  listenQueryParam(): void {
    this.subscriptions.add(this.route.queryParams.subscribe((params) => {
      if (params && params.q) {
        const q = JSON.parse(atob(params.q));
        this.expired = JSON.parse(q.expired);
        this.uuid = q.uuid;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
