import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(
    private http: HttpClient
  ) { }

  fetch(link: string): Promise<any> {
    const url = `${environment.apiAddress}/fluxo-aprovacao/links/${link}`;
    return this.http.get(url).toPromise();
  }
}
