import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoaderModule } from '../shared/loader/loader.module';
import { SharedModule } from '../shared/shared.module';
import { AutenticacaoFormComponent } from './autenticacao-form/autenticacao-form.component';
import { AuthGuardService } from './autenticacao-guard';
import { AutenticacaoPageComponent } from './autenticacao-page/autenticacao-page.component';
import { AutenticacaoService } from './autenticacao.service';
import { CredenciaisHttpInterceptor } from './credenciais-http.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    LoaderModule,
    SharedModule
  ],
  declarations: [
    AutenticacaoPageComponent,
    AutenticacaoFormComponent
  ],
  providers: [
    AuthGuardService,
    AutenticacaoService,
    { provide: HTTP_INTERCEPTORS, useClass: CredenciaisHttpInterceptor, multi: true }
  ]
})
export class AutenticacaoModule { }
