import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StatusAprovacaoEnum } from 'src/app/shared/models/enums/status-aprovacao.enum';
import ItensAprovacao from 'src/app/shared/models/itens-aprovacao.model';
import { NewMensagensService } from 'src/app/shared/new-mensagens/new-mensagens.service';
import { FluxoAprovacaoService } from 'src/app/shared/service/fluxo-aprovacao.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { FluxoAprovacaoObservable } from '../fluxo-aprovacao.observable';
@Component({
  selector: 'app-para-aprovar-page',
  templateUrl: './para-aprovar-page.component.html',
  styleUrls: ['./para-aprovar-page.component.scss'],
})
export class ParaAprovarPageComponent implements OnInit, OnDestroy {
  itensAprovacao = new ItensAprovacao();
  itensAprovacaoAux = new ItensAprovacao();
  statusAprovacao = null;

  subscriptions = new Subscription();

  qtde = 0;
  uuidLink: string;
  uuid: string;
  show = false;

  constructor(
    private fluxoAprovacaoService: FluxoAprovacaoService,
    private loaderService: LoaderService,
    private mensagensService: NewMensagensService,
    private $fluxoAprovacao: FluxoAprovacaoObservable,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.statusAprovacao = StatusAprovacaoEnum.EM_APROVACAO_NVL2;
    this.itensAprovacao = new ItensAprovacao();
    this.listenFluxoAprovacao();
    this.listByStatusAprovacao();
    this.listenQueryParam();
  }

  listenQueryParam(): void {
    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params && params.q) {
          const q = JSON.parse(atob(params.q));
          this.uuidLink = q.uuid;
          this.uuid = q.uuidApuracao;
        }
      })
    );
  }

  listenFluxoAprovacao(): void {
    this.subscriptions.add(
      this.$fluxoAprovacao.subscribe(async (resposta) => {
        this.router.navigate(['/para-aprovar'], { queryParams: {} });
        this.uuidLink = null;
        this.uuid = null;
        await this.listByStatusAprovacao(true);
      })
    );
  }

  async listByStatusAprovacao(showMessage?: boolean): Promise<void> {
    try {
      this.loaderService.start();
      this.mensagensService.limparMensagens();
      this.itensAprovacao = new ItensAprovacao();
      this.itensAprovacaoAux =
        await this.fluxoAprovacaoService.listByStatusAprovacao(
          StatusAprovacaoEnum.EM_APROVACAO_NVL2
        );
      this.qtde = 0;
      if (
        this.itensAprovacaoAux &&
        this.itensAprovacaoAux.calculos &&
        this.itensAprovacaoAux.calculos.length > 0
      ) {
        this.qtde = this.itensAprovacaoAux.calculos.length;
        if (this.uuid && !this.show) {
          this.itensAprovacao.calculos = [];
          const item = this.itensAprovacaoAux.calculos.filter(
            (calc) => calc.uuid === this.uuid || (calc.calculos?.length && calc.calculos.some(elem => elem.uuid === this.uuid))
          )[0];
          if (item?.uuid) {
            this.itensAprovacao.calculos.push(item);
          }
        } else {
          this.itensAprovacao.calculos = this.itensAprovacaoAux.calculos;
        }
      }
      if (
        this.itensAprovacaoAux &&
        this.itensAprovacaoAux.verbasExtra &&
        this.itensAprovacaoAux.verbasExtra.length > 0
      ) {
        this.qtde = this.itensAprovacaoAux.verbasExtra.length;
        if (this.uuid && !this.show) {
          this.itensAprovacao.verbasExtra = [];
          const item = this.itensAprovacaoAux.verbasExtra.filter(
            (calc) => calc.uuid === this.uuid
          )[0];
          if (item?.uuid) {
            this.itensAprovacao.verbasExtra.push(item);
          }
        } else {
          this.itensAprovacao.verbasExtra = this.itensAprovacaoAux.verbasExtra;
        }
      }
      if (
        this.itensAprovacaoAux &&
        this.itensAprovacaoAux.campanhas &&
        this.itensAprovacaoAux.campanhas.length > 0
      ) {
        this.qtde = this.qtde + this.itensAprovacaoAux.campanhas.length;
        if (this.uuid && !this.show) {
          this.itensAprovacao.campanhas = [];
          const item = this.itensAprovacaoAux.campanhas.filter(
            (camp) => camp.uuid === this.uuid
          )[0];
          if (item?.uuid) {
            this.itensAprovacao.campanhas.push(item);
          }
        } else {
          this.itensAprovacao.campanhas = this.itensAprovacaoAux.campanhas;
        }
      }

      const campanhas =
        this.itensAprovacao &&
        this.itensAprovacao.campanhas &&
        this.itensAprovacao.campanhas.length > 0;
      const calculos =
        this.itensAprovacao &&
        this.itensAprovacao.calculos &&
        this.itensAprovacao.calculos.length > 0;
      const verbasExtra =
        this.itensAprovacao &&
        this.itensAprovacao.verbasExtra &&
        this.itensAprovacao.verbasExtra.length > 0;
      if (this.uuid && !calculos && !campanhas && !verbasExtra) {
        const param = { uuid: this.uuidLink, expired: false };
        this.router.navigate(['link'], {
          queryParams: { q: btoa(JSON.stringify(param)) },
        });
      } else if (!this.uuid || this.show) {
        this.itensAprovacao = this.itensAprovacaoAux;
      }

      if (showMessage) {
        this.mensagensService.sucesso(['Reprovação realizada com sucesso.']);
      }
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  handleShow(): void {
    this.show = !this.show;
    this.listByStatusAprovacao(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
