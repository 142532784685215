<div class="page">
  <div class="message">
    <div class="header">
      <img
        src="./../../assets/images/gcb/logo-gcb.svg"
        alt="Logo Via"
      >
    </div>
    <div class="content">
      <div *ngIf="expired">
        Existe uma nova apuração de bonificação aguardando aprovação.
        <br /> <br />
        <span style="color: #CF1E1E; font-weight: 600;">Mas este link de acesso está expirado.</span>
        <br /> <br />
        Por favor verifique seu e-mail com o link mais recente enviado para acessar.
      </div>
      <div *ngIf="!expired">
        <span style="color: #CF1E1E; font-weight: 600;">Esta apuração já foi analizada.</span> <br />
        Portanto este link não está mais disponível.
        <br /> <br />
        Por favor desconsidere este link e acesse seu portal para verificar novas apurações pendentes.
      </div>
    </div>
    <div class="footer">
      <p class="copyright">
        © Copyright - Grupo Casas Bahia S/A - Todos os direitos reservados.
      </p>
    </div>
  </div>
  <div class="title">
    <a
      mat-button
      href="/app"
    >
      VOLTAR
    </a>
    <h1>Apuração <span>de bonificação</span></h1>
  </div>
</div>
