<header>
  <div class="containerExterno">
    <div class="col4">
      <img
        class="logotipo"
        src="assets/images/gcb/logo-gcb-bonificacao.svg"
        alt="Grupo Casas Bahia S/A | Apuração de Bonificação"
      >
    </div>
    <div class="col8 minhaConta">
      <span
        [style.color]="ativo('/home') ? '#006700' : 'white'"
        class="material-icons-regular"
        (click)="ir('auth')"
      >
        exit_to_app
      </span>
      <p [style.color]="ativo('/home') ? '#006700' : 'white'">
        {{usuario.usuario}}
      </p>
      <div class="help">
        <a>
          <img
            src="/assets/images/login/ic_help.svg"
            alt="Ajuda"
            class="ajuda"
          >
          AJUDA
        </a>
      </div>
    </div>
  </div>
</header>

<nav #menu>
  <div class="containerExterno">
    <ul>
      <li
        ngClass="{{ ativo('/para-aprovar') === true ? 'ativo' : ''}}"
        class="w-50"
      >
        <a
          href="#/para-aprovar"
          class="w-100"
          id="menuParaAprovar"
        >PARA APROVAR</a>
      </li>
      <li
        ngClass="{{ ativo('/reprovadas') === true ? 'ativo' : ''}}"
        class="w-50"
      >
        <a
          href="#/reprovadas"
          class="w-100"
          id="menuReprovadas"
        >REPROVADAS</a>
      </li>
    </ul>
  </div>
</nav>
