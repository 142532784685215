import { Component, Input, OnInit } from '@angular/core';
import { FluxoAprovacaoObservable } from 'src/app/para-aprovar/fluxo-aprovacao.observable';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
import { ConfiguracaoModalExportacao } from '../modal/modal-exportacao/modal-exportacao.model';
import { ModalExportacaoObservable } from '../modal/modal-exportacao/modal-exportacao.observable';
import { ConfiguracaoModalInfoAgrupamento } from '../modal/modal-info-agrupamento/modal-info-agrupamento.model';
import { ModalInfoAgrupamentoObservable } from '../modal/modal-info-agrupamento/modal-info-agrupamento.observable';
import CalculoBonificacao from '../models/calculo-bonificacao.model';
import CampanhaBonificacao from '../models/campanha-bonificacao.model';
import { TipoAprovacao } from '../models/enums/tipo-aprovacao.enum';
import FileModel from '../models/file/file.model';
import { TipoFile } from '../models/file/tipo-file';
import { FluxoAprovacaoRequest } from '../models/fluxo-aprovacao-request.model';
import ItensAprovacao from '../models/itens-aprovacao.model';
import { Marca } from '../models/marca.model';
import VerbaExtra2 from '../models/verba-extra-2.model';
import { FluxoAprovacaoService } from '../service/fluxo-aprovacao.service';
import { MotivoReprovacaoService } from '../service/motivo-reprovacao.service';
import { TipoRelatorio } from './../modal/modal-exportacao/tipo-relatorio.enum';
import { StatusAprovacaoEnum } from './../models/enums/status-aprovacao.enum';
import { NewMensagensService } from './../new-mensagens/new-mensagens.service';

@Component({
  selector: 'app-calculo-item-form',
  templateUrl: './calculo-item-form.component.html',
  styleUrls: ['./calculo-item-form.component.scss'],
})
export class CalculoItemFormComponent implements OnInit {
  @Input()
  itensAprovacao = new ItensAprovacao();
  @Input()
  statusAprovacao = null;

  isFluxoAprovacao = false;

  itensReprovacao: any;
  model: FileModel = new FileModel();
  file!: File | null;
  extensoesPermitidas = ['jpeg', 'png', 'jpg', 'pdf', 'doc', 'docx'];

  timers = new Map<string, number>();
  intervals = new Map<string, any>();
  timeouts = new Map<string, any>();

  constructor(
    private fluxoAprovacaoService: FluxoAprovacaoService,
    private loaderService: LoaderService,
    private mensagensService: NewMensagensService,
    private motivoReprovacaoService: MotivoReprovacaoService,
    private $fluxoAprovacao: FluxoAprovacaoObservable,
    private $modalExportacao: ModalExportacaoObservable,
    private $modalInfoAgrupamento: ModalInfoAgrupamentoObservable
  ) {}

  ngOnInit() {
    if (this.statusAprovacao === StatusAprovacaoEnum.EM_APROVACAO_NVL2) {
      this.isFluxoAprovacao = true;
    }
    this.listDisapproveReasons();
  }

  async listDisapproveReasons(): Promise<void> {
    this.itensReprovacao =
      await this.motivoReprovacaoService.listDisapproveReasons();
  }

  obterTotalNET(item: CalculoBonificacao): number {
    let net = 0;
    if (item.valorNETRecebimento) {
      net += item.valorNETRecebimento;
    }
    if (item.valorNETAgendamento) {
      net += item.valorNETAgendamento;
    }

    return net;
  }

  test(string: string): boolean {
    const couldBySucess = 'couldBeSucess';
    let fail = 'fail';
    if (couldBySucess === string) {
      return false;
    }
    if (fail === string) {
      fail = 'success';
    }
    return string === 'success';
  }

  obterTotalVerbaGerada(item: CalculoBonificacao): number {
    let total = 0;
    if (item.valorVerbaGeradaRecebimentoValorBruto) {
      total += item.valorVerbaGeradaRecebimentoValorBruto;
    }
    if (item.valorVerbaGeradaRecebimentoValorLiquido) {
      total += item.valorVerbaGeradaRecebimentoValorLiquido;
    }
    if (item.valorVerbaGeradaAgendamentoValorBruto) {
      total += item.valorVerbaGeradaAgendamentoValorBruto;
    }
    if (item.valorVerbaGeradaAgendamentoValorLiquido) {
      total += item.valorVerbaGeradaAgendamentoValorLiquido;
    }
    return total;
  }

  private showFlow(identificador: string, isReprovar: boolean): void {
    const card = document.getElementById(identificador);
    card.classList.add('hidden');
    card.classList.remove('show');
    let idCardFlow = 'fluxo_aprovado' + identificador;
    if (isReprovar) {
      idCardFlow = 'fluxo_reprovado' + identificador;
    }
    const cardFlow = document.getElementById(idCardFlow);
    cardFlow.classList.add('show');
    cardFlow.classList.remove('hidden');
    const btn = document.getElementById('btn' + identificador);
    btn.classList.add('hidden');
    btn.classList.remove('actions');
  }

  private showMain(identificador: string): void {
    const card = document.getElementById(identificador);
    card.classList.add('show');
    card.classList.remove('hidden');
    const cardFlowReaprovall = document.getElementById(
      'fluxo_reprovado' + identificador
    );
    cardFlowReaprovall.classList.add('hidden');
    cardFlowReaprovall.classList.remove('show');
    const cardFlowAprovall = document.getElementById(
      'fluxo_aprovado' + identificador
    );
    cardFlowAprovall.classList.add('hidden');
    cardFlowAprovall.classList.remove('show');
    const btn = document.getElementById('btn' + identificador);
    btn.classList.add('actions');
    btn.classList.remove('hidden');
  }

  doApprovalCalculo(item: CalculoBonificacao): void {
    if (item.status) {
      this.flow(
        !item.calculos?.length ? item.uuid : null,
        StatusAprovacaoEnum.APROVADO_NVL2,
        TipoAprovacao.CALCULO_BONIFICACAO,
        false,
        item.motivo,
        null,
        null,
        item.calculos?.length ? item.uuid : null
      );
      clearTimeout(this.timeouts.get(item.uuid));
    }
  }

  private runTimer(item: any): void {
    let timer = this.timers.get(item.uuid);
    timer--;
    this.timers.delete(item.uuid);
    this.timers.set(item.uuid, timer);
    if (timer === 0) {
      clearInterval(this.intervals.get(item.uuid));
      this.timers.delete(item.uuid);
    }
  }

  onApprovalCalculo(item: CalculoBonificacao): void {
    try {
      this.loaderService.startNotScroll();
      item.status = StatusAprovacaoEnum.APROVADO_NVL2;
      this.showFlow(item.identificador, false);

      clearTimeout(this.timeouts.get(item.uuid));
      clearInterval(this.intervals.get(item.uuid));
      this.timeouts.delete(item.uuid);
      this.intervals.delete(item.uuid);
      this.timers.delete(item.uuid);

      this.timeouts.set(
        item.uuid,
        setTimeout(async () => this.doApprovalCalculo(item), 10000)
      );
      this.timers.set(item.uuid, 10);
      this.intervals.set(
        item.uuid,
        setInterval(async () => this.runTimer(item), 1000)
      );
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
      const calculo = document.getElementById(`calculo_${item.identificador}`);
      if (this.fullWidth <= 920) {
        calculo.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }

  onReapprovalCalculo(item: CalculoBonificacao): void {
    item.status = StatusAprovacaoEnum.REPROVADO_NVL2;
    this.showFlow(item.identificador, true);
    const calculo = document.getElementById(`calculo_${item.identificador}`);
    if (this.fullWidth <= 920) {
      calculo.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  async onSendReprovalCalculo(item: CalculoBonificacao): Promise<void> {
    try {
      if (!item.motivo || item.motivo.trim().length === 0) {
        this.mensagensService.erro(['Campo obrigatório não preenchido']);
      } else {
        this.loaderService.start();
        await this.flow(
          !item.calculos?.length ? item.uuid : null,
          StatusAprovacaoEnum.REPROVADO_NVL2,
          TipoAprovacao.CALCULO_BONIFICACAO,
          false,
          item.motivo,
          item.idMotivoReprovacao,
          item.arquivosReprovados,
          item.calculos?.length ? item.uuid : null
        );
        this.$fluxoAprovacao.next(null);
      }
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  onCancelCalculo(item: CalculoBonificacao): void {
    item.status = null;
    this.showMain(item.identificador);
  }

  onUndoCalculo(item: CalculoBonificacao): void {
    clearInterval(this.intervals.get(item.uuid));
    clearTimeout(this.timeouts.get(item.uuid));
    try {
      this.loaderService.startNotScroll();
      item.status = null;
      this.showMain(item.identificador);
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  get fullWidth(): number {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.body.clientWidth,
      document.documentElement.clientWidth
    );
  }

  // Serviços de campanha

  async doApprovalCampanha(item: CampanhaBonificacao) {
    if (item.status) {
      this.flow(
        item.uuid,
        StatusAprovacaoEnum.APROVADO_NVL2,
        TipoAprovacao.CAMPANHA_BONIFICACAO,
        false,
        item.motivo,
        null,
        null
      );
      clearTimeout(this.timeouts.get(item.uuid));
    }
  }

  async onApprovalCampanha(item: CampanhaBonificacao) {
    try {
      this.loaderService.startNotScroll();
      item.statusAprovacao = StatusAprovacaoEnum.APROVADO_NVL2;
      this.showFlow(item.identificador, false);

      clearInterval(this.intervals.get(item.uuid));

      this.timeouts.delete(item.uuid);
      this.intervals.delete(item.uuid);
      this.timers.delete(item.uuid);

      this.timeouts.set(
        item.uuid,
        setTimeout(async () => this.doApprovalCampanha(item), 10000)
      );
      this.timers.set(item.uuid, 10);
      this.intervals.set(
        item.uuid,
        setInterval(async () => this.runTimer(item), 1000)
      );
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
      const campanha = document.getElementById(
        `campanha_${item.identificador}`
      );
      if (this.fullWidth <= 920) {
        campanha.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }

  onReapprovalCampanha(item: CampanhaBonificacao): void {
    item.statusAprovacao = StatusAprovacaoEnum.REPROVADO_NVL2;
    this.showFlow(item.identificador, true);
    const campanha = document.getElementById(`campanha_${item.identificador}`);
    if (this.fullWidth <= 920) {
      campanha.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  async onSendReprovalCampanha(item: CampanhaBonificacao): Promise<void> {
    try {
      if (!item.motivo || item.motivo.trim().length === 0) {
        this.mensagensService.erro(['Campo obrigatório não preenchido']);
      } else {
        this.loaderService.start();
        await this.flow(
          item.uuid,
          StatusAprovacaoEnum.REPROVADO_NVL2,
          TipoAprovacao.CAMPANHA_BONIFICACAO,
          false,
          item.motivo,
          item.idMotivoReprovacao,
          item.arquivosReprovados
        );
        this.$fluxoAprovacao.next(null);
      }
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  onCancelCampanha(item: CampanhaBonificacao): void {
    item.statusAprovacao = null;
    this.showMain(item.identificador);
  }

  onUndoCampanha(item: CampanhaBonificacao): void {
    clearInterval(this.intervals.get(item.uuid));
    clearTimeout(this.timeouts.get(item.uuid));
    try {
      this.loaderService.startNotScroll();
      item.status = null;
      this.showMain(item.identificador);
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  async flow(
    uuid: string,
    status: StatusAprovacaoEnum,
    tipoAprovacao: TipoAprovacao,
    cancel: boolean,
    motivo: string,
    idMotivoReprovacao: number,
    arquivos: FileModel[],
    uuidAgrupador?: string
  ): Promise<void> {
    const request = new FluxoAprovacaoRequest();
    request.uuid = uuid;
    request.uuidAgrupador = uuidAgrupador;
    request.status = status;
    request.tipo = tipoAprovacao;
    request.arquivos = arquivos;
    request.idMotivoReprovacao = idMotivoReprovacao;
    if (cancel) {
      await this.fluxoAprovacaoService.cancelFlow(request);
    } else {
      if (status === StatusAprovacaoEnum.REPROVADO_NVL2) {
        request.comentarios = motivo;
      }
      await this.fluxoAprovacaoService.flow(request);
    }
  }

  abrirModalIdentificadores(item: CalculoBonificacao): void {
    const configuracao = new ConfiguracaoModalInfoAgrupamento();
    configuracao.title = 'Identificadores';
    configuracao.subtitle = `Categoria: ${item.categoria?.id} - ${item.categoria?.descricao}`;

    let lista: any[] = [];

    item.identificadores?.map((identificador: any) => {
      if (identificador.identificador) {
        let identificadorItem: any = {};
        identificadorItem.id = Number(
          identificador.identificador.replace('vv', '')
        );
        identificadorItem.descricao = identificador.identificador;
        lista.push(identificadorItem);
      }
    });

    lista = configuracao.itens = lista.map((item) => {
      item.subItens?.map((subitem) => {
        subitem.selecionado = false;
        subitem.parent = item.id;
      });

      item.selecionado = true;

      return item;
    });
    configuracao.numeroColunas = 6;
    this.$modalInfoAgrupamento.next(configuracao);
  }

  abrirModalMarcas(item: CalculoBonificacao): void {
    const configuracao = new ConfiguracaoModalInfoAgrupamento();
    configuracao.title = 'Marcas';
    configuracao.subtitle = `Categoria: ${item.categoria?.id} - ${item.categoria?.descricao}`;

    let lista: any[] = [];

    item.marcas.forEach((marca: Marca) => {
      if (marca && marca?.descricao) {
        let marcatem: any = {};
        marcatem.id = Number(marca.id);
        marcatem.descricao = marca?.id + ' - ' + marca?.descricao;
        lista.push(marcatem);
      }
    });

    lista = configuracao.itens = lista.map((item) => {
      item.subItens?.map((subitem) => {
        subitem.selecionado = false;
        subitem.parent = item.id;
      });

      item.selecionado = true;

      return item;
    });
    this.loaderService.stop();
    this.$modalInfoAgrupamento.next(configuracao);
  }

  onViewCalculationMemoryCampanha(item: CampanhaBonificacao): void {
    const configuracao = new ConfiguracaoModalExportacao();
    configuracao.tipoRelatorio = TipoRelatorio.APURACAO_POR_VENDA;
    configuracao.url = `${environment.apiAddress}/arquivos-externo/memoria-calculo/CAMPANHA_BONIFICACAO/${item.uuid}/export`;
    this.$modalExportacao.next(configuracao);
  }

  onViewCalculationMemoryCalculo(item: CalculoBonificacao): void {
    const configuracao = new ConfiguracaoModalExportacao();
    configuracao.tipoRelatorio = TipoRelatorio.APURACAO_POR_RECEBIMENTO;
    const tipoAprovacao = item.calculos?.length
      ? 'AGRUPADOR_CALCULO_BONIFICACAO'
      : 'CALCULO_BONIFICACAO';
    configuracao.url = `${environment.apiAddress}/arquivos-externo/memoria-calculo/${tipoAprovacao}/${item.uuid}/export`;
    this.$modalExportacao.next(configuracao);
  }

  onCancelVerbaExtra(item: VerbaExtra2): void {
    item.status = null;
    item.statusAprovacao = StatusAprovacaoEnum.EM_APROVACAO_NVL2;
    this.showMain(item.identificador);
  }

  onUndoVerbaExtra(item: VerbaExtra2): void {
    clearInterval(this.intervals.get(item.uuid));
    clearTimeout(this.timeouts.get(item.uuid));
    try {
      this.loaderService.startNotScroll();
      item.status = null;
      item.statusAprovacao = StatusAprovacaoEnum.EM_APROVACAO_NVL2;
      this.showMain(item.identificador);
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  async onSendReprovalVerbaExtra(item: VerbaExtra2): Promise<void> {
    try {
      if (!item.motivo || item.motivo.trim().length === 0) {
        this.mensagensService.erro(['Campo obrigatório não preenchido']);
      } else {
        this.loaderService.start();
        await this.flow(
          item.uuid,
          StatusAprovacaoEnum.REPROVADO_NVL2,
          TipoAprovacao.VERBA_EXTRA,
          false,
          item.motivo,
          item.idMotivoReprovacao,
          item.arquivosReprovados
        );
        this.$fluxoAprovacao.next(null);
      }
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
    }
  }

  onApprovalVerbaExtra(item: VerbaExtra2): void {
    try {
      this.loaderService.startNotScroll();
      item.status = StatusAprovacaoEnum.APROVADO_NVL2;
      this.showFlow(item.identificador, false);

      clearTimeout(this.timeouts.get(item.uuid));
      clearInterval(this.intervals.get(item.uuid));
      this.timeouts.delete(item.uuid);
      this.intervals.delete(item.uuid);
      this.timers.delete(item.uuid);

      this.timeouts.set(
        item.uuid,
        setTimeout(async () => this.doApprovalVerbaExtra(item), 10000)
      );
      this.timers.set(item.uuid, 10);
      this.intervals.set(
        item.uuid,
        setInterval(async () => this.runTimer(item), 1000)
      );
    } catch (response) {
      if (response.error) {
        this.mensagensService.erro([response.error.message]);
      }
    } finally {
      this.loaderService.stop();
      const calculo = document.getElementById(
        `verba_extra_${item.identificador}`
      );
      if (this.fullWidth <= 920) {
        calculo.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }

  async doApprovalVerbaExtra(item: VerbaExtra2) {
    if (item.status) {
      this.flow(
        item.uuid,
        StatusAprovacaoEnum.APROVADO_NVL2,
        TipoAprovacao.VERBA_EXTRA,
        false,
        item.motivo,
        null,
        null
      );
      clearTimeout(this.timeouts.get(item.uuid));
    }
  }

  onReapprovalVerbaExtra(item: VerbaExtra2): void {
    item.status = StatusAprovacaoEnum.REPROVADO_NVL2;
    this.showFlow(item.identificador, true);
    const verbaExtra = document.getElementById(
      `verba_extra_${item.identificador}`
    );
    if (this.fullWidth <= 920) {
      verbaExtra.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  onInputClick(evt: any) {
    evt.target.value = '';
  }

  async changeFile(arquivos: FileModel[], evt: any): Promise<void> {
    this.mensagensService.limparMensagens();
    let files: FileList;
    if (evt instanceof FileList) {
      files = evt;
    } else {
      files = evt.target.files;
    }
    this.file = null;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files && files !== undefined && files.length && arquivos) {
          const item = files.item(i);
          const size = item ? item.size : 0;
          const filesizeMb = Number((size / 1024 / 1024).toFixed(4)); // MB
          if (size === 0) {
            this.mensagensService.aviso(['Documento em branco.']);
          } else if (filesizeMb > 10) {
            this.mensagensService.aviso([
              'O tamanho máximo para o documento é de 10MB.',
            ]);
          } else {
            const extensaoSplit = files[i].name.split('.');
            const extensao =
              extensaoSplit[extensaoSplit.length - 1].toLowerCase();
            const index = this.extensoesPermitidas.indexOf(extensao);
            if (index < 0) {
              this.mensagensService.aviso([
                'Arquivo inválido. Permitidos arquivos dos tipos PDF, Word, JPG, JPEG ou PNG.',
              ]);
            } else {
              const found = arquivos.some(
                (arquivo: FileModel) =>
                  arquivo.nome === files.item(i)?.name &&
                  arquivo.tamanho === filesizeMb
              );
              if (!found) {
                this.file = files.item(i);
                const arquivo = new FileModel();
                arquivo.nome = this.file?.name;
                arquivo.tamanho = filesizeMb;
                arquivo.extensao = extensao;

                switch (extensao) {
                  case 'pdf':
                    arquivo.tipoFile = TipoFile.PDF;
                    break;
                  case 'doc':
                    arquivo.tipoFile = TipoFile.DOC;
                    break;
                  case 'docx':
                    arquivo.tipoFile = TipoFile.DOCX;
                    break;
                  case 'jpeg':
                    arquivo.tipoFile = TipoFile.JPEG;
                    break;
                  case 'jpg':
                    arquivo.tipoFile = TipoFile.JPG;
                    break;
                  case 'png':
                    arquivo.tipoFile = TipoFile.PNG;
                    break;
                  default:
                    break;
                }
                if (this.file && arquivos) {
                  arquivo.fileBase64 = await this.fileToBase64(this.file);
                  if (arquivos.length > 9) {
                    this.mensagensService.aviso([
                      'Limite máximo permitido de 10 arquivos',
                    ]);
                    return;
                  }
                  arquivos.push(arquivo);
                }
              } else {
                this.mensagensService.aviso([
                  'Arquivo já existe! O arquivo já está na lista',
                ]);
              }
            }
          }
        }
      }
    }
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(reader.result ? reader.result.toString() : '');
      reader.onerror = (error) => reject(error);
    });
  }

  removerArquivo(arquivos: FileModel[], arquivo: FileModel): void {
    if (arquivos) {
      arquivos.splice(arquivos.indexOf(arquivo), 1);
    }
  }
}
