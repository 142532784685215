import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfiguracaoModal } from './configuracao-modal.model';

@Injectable()
export class ConfiguracaoModalObservable extends Subject<ConfiguracaoModal> {

  constructor() {
    super();
  }
}
