import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MensagensModule } from '../shared/mensagens/mensagens.module';
import { NewMensagensModule } from '../shared/new-mensagens/new-mensagens.module';
import { SharedModule } from '../shared/shared.module';
import { FluxoAprovacaoObservable } from './fluxo-aprovacao.observable';
import { ParaAprovarPageComponent } from './para-aprovar-page/para-aprovar-page.component';

@NgModule({
  declarations: [ParaAprovarPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    MensagensModule,
    NewMensagensModule,
  ],
  exports: [ParaAprovarPageComponent],
  providers: [FluxoAprovacaoObservable],
})
export class ParaAprovarModule {}
