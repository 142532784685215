import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkService } from 'src/app/link-expirado/link.service';
import Autenticacao from '../../shared/models/autenticacao-model';
import { LoaderService } from './../../shared/loader/loader.service';
import { AutenticacaoService } from './../autenticacao.service';


@Component({
  selector: 'app-autenticacao-page',
  templateUrl: './autenticacao-page.component.html',
  styleUrls: ['./autenticacao-page.component.scss'],
})
export class AutenticacaoPageComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  autenticacaoError: string | null = null;
  link: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private autenticacaoService: AutenticacaoService,
    private linkService: LinkService,
    private loaderService: LoaderService
  ) {
    this.loaderService.start();
  }

  ngOnInit(): void {
    this.autenticacaoService.logout();
    this.loaderService.stop();
    this.listenQueryParam();
  }

  listenQueryParam(): void {
    this.subscriptions.add(this.route.queryParams.subscribe((params) => {
      if (params && params.q) {
        this.link = params.q;
      }
    }));
  }

  async login(autenticacao: Autenticacao): Promise<void> {
    try {
      this.loaderService.start();
      this.autenticacaoError = null;
      await this.autenticacaoService.login(autenticacao);
      this.autenticacaoError = null;
      if (this.link) {
        const uuid = atob(this.link);
        this.linkService.fetch(uuid)
          .then(response => {
            const param = { uuid, uuidApuracao: response.uuidApuracao };
            this.router.navigate(['para-aprovar'], { queryParams: { q: btoa(JSON.stringify(param)) } });
          })
          .catch(reason => {
            if (reason.status === 422) {
              const message = reason?.error?.message;
              if (message === 'LINK_ACESSO_NAO_AUTORIZADO') {
                this.autenticacaoError = 'Este usuário não tem permissão para acessar o link informado.';
                this.autenticacaoService.logout();
              } else {
                const param = { uuid, expired: message === 'LINK_EXPIRADO' };
                this.router.navigate(['link'], { queryParams: { q: btoa(JSON.stringify(param)) } });
              }
            }
          });
      } else {
        this.router.navigate(['para-aprovar']);
      }
    } catch (error) {
      let message = '';
      if (error?.status) {
        if (error?.status === 403 || error?.status === 401 || error?.status === 400) {
          message = 'Login ou Senha Inválidos';
        }
      } else if ('Token inválido' === error?.message) {
        message = 'Login ou Senha Inválidos';
      } else {
        message = 'Ocorreu um erro ao tentar processar sua solicitação. Tente novamente mais tarde.';
      }
      this.autenticacaoError = message;
    } finally {
      this.loaderService.stop();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
