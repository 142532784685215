import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './../../shared/loader/loader.service';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  time;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.inactivityTime();
  }
  inactivityTime(): void {
    window.onload = () => this.resetTimer();
    // DOM Events
    document.onmousemove = () => this.resetTimer();
    document.onkeypress = () => this.resetTimer();
  }

  resetTimer() {
    clearTimeout(this.time);
    this.time = setTimeout(() => this.logout(), 600000);
  }

  logout() {
    this.router.navigate(['auth']);
  }

}
