import { LoaderService } from './../shared/loader/loader.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { DadosRotaAutenticada } from './../shared/models/dados-rota-autenticada';
import { AutenticacaoService } from './autenticacao.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private loaderService: LoaderService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      this.loaderService.start();
      const dados = route.data as DadosRotaAutenticada;
      const temPermissao = await this.autenticacaoService.temPermissao(dados);
      if (!temPermissao) {
        this.router.navigate(['auth']);
      }
      return temPermissao;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.loaderService.stop();
    }
  }

}
