import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit'
})
export class DelimitorPipe implements PipeTransform {

  transform(valor: string, limit: number | string = 15): string {
    const limitNumber = Number(limit);
    if (valor?.trim().length <= limitNumber) {
      return valor;
    }
    return valor.trim().substring(0, limitNumber - 3) + '...';
  }

}
