import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.type.toString() === 'PRD') {
  const scriptEl = window.document.createElement('script');
  scriptEl.src = './assets/scripts/hotjar.js';
  window.document.body.appendChild(scriptEl);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
