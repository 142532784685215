import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { version } from './../../../../../package.json';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public version: string = version;
  rotaPermitida: boolean;

  constructor(
    private router: Router,
  ) { }

  isNotActive(link) {
    link = link.slice(0, -1);
    return !this.router.url.startsWith(link);
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
