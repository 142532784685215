import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MensagensComponent } from './mensagens.component';
import { MensagensService } from './mensagens.service';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [
    MensagensComponent
  ],
  exports: [
    MensagensComponent,
  ],
  providers: [
    MensagensService
  ]
})
export class MensagensModule { }
