import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';
import Autenticacao from '../../shared/models/autenticacao-model';

@Component({
  selector: 'app-autenticacao-form',
  templateUrl: './autenticacao-form.component.html',
  styleUrls: ['./autenticacao-form.component.scss']
})
export class AutenticacaoFormComponent implements OnInit {

  hide = true;
  lembrarEmailChecked: boolean;
  email = this.localStorageService.get('email');

  @Output()
  login: EventEmitter<Autenticacao> = new EventEmitter<Autenticacao>();

  @Input()
  erro: string;

  autenticacao = new Autenticacao();

  constructor(
    private router: Router,
    public loaderService: LoaderService,
    private localStorageService: LocalStorageService
  ) { }
  ///////
  ngOnInit() {
    this.populaInputEmail(this.email);
  }

  handleLogin(): void {
    this.login.emit(this.autenticacao);
  }

  acionarMenu(url: string) {
    this.router.navigate([url]);
  }

  lembrarEmail() {
    if (this.lembrarEmailChecked) {
      this.localStorageService.set('email', this.autenticacao.usuario);
    } else {
      this.localStorageService.remove('email');
    }
  }

  populaInputEmail(email) {
    if (email !== null) {
      this.autenticacao.usuario = email;
    }
  }
}
