import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NewMensagensModule } from '../shared/new-mensagens/new-mensagens.module';
import { SharedModule } from '../shared/shared.module';
import { ReprovadosPageComponent } from './reprovados-page/reprovados-page.component';

@NgModule({
  declarations: [ReprovadosPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NewMensagensModule,
    SharedModule,
  ],
  exports: [ReprovadosPageComponent],
})
export class ReprovadosModule {}
