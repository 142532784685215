import { Injectable } from '@angular/core';

@Injectable()
export class PrecoService {

  transform(preco: number): string {
    if (typeof preco !== 'number' || isNaN(preco)) {
      return '';
    }

    const formato = { style: 'currency', currency: 'BRL' };
    return Number(preco).toLocaleString('pt-BR', formato).replace(/^R\$(\s)*/, 'R$ ');
  }

}
