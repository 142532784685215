import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../app-routing.module';
import { AvisoComponent } from './aviso/aviso.component';
import { CalculoItemFormComponent } from './calculo-item-form/calculo-item-form.component';
import { DataService } from './data.service';
import { DragDropDirective } from './directives/drag-drop.directive';
import { BreadcrumbComponent } from './layout/breadcrumb/breadcrumb.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { MenuHeaderComponent } from './layout/menu-header/menu-header.component';
import { LoaderModule } from './loader/loader.module';
import { MensagensModule } from './mensagens/mensagens.module';
import { ModalExportacaoComponent } from './modal/modal-exportacao/modal-exportacao.component';
import { ModalExportacaoObservable } from './modal/modal-exportacao/modal-exportacao.observable';
import { TipoRelatorioPipe } from './modal/modal-exportacao/tipo-relatorio.pipe';
import { ModalInfoAgrupamentoComponent } from './modal/modal-info-agrupamento/modal-info-agrupamento.component';
import { ModalInfoAgrupamentoObservable } from './modal/modal-info-agrupamento/modal-info-agrupamento.observable';
import { ExtensaoFilePipe } from './models/file/extensao-file.pipe';
import { DelimitorPipe } from './models/pipe/delimitor.pipe';
import { ModalidadePipe } from './models/pipe/modalidade.pipe';
import { TipoQuantidadePipe } from './models/pipe/tipo-quantidade.pipe';
import { NewMensagensModule } from './new-mensagens/new-mensagens.module';
import { PrecoModule } from './preco/preco.module';
@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    LoaderModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    PrecoModule,
    FormsModule,
    RouterModule,
    MensagensModule,
    NewMensagensModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LayoutComponent,
    BreadcrumbComponent,
    MenuHeaderComponent,
    FooterComponent,
    AvisoComponent,
    CalculoItemFormComponent,
    DelimitorPipe,
    ModalidadePipe,
    ExtensaoFilePipe,
    TipoQuantidadePipe,
    TipoRelatorioPipe,
    ModalExportacaoComponent,
    ModalInfoAgrupamentoComponent,
    DragDropDirective
  ],
  exports: [
    LayoutComponent,
    BreadcrumbComponent,
    DelimitorPipe,
    ExtensaoFilePipe,
    CalculoItemFormComponent,
    MatCheckbox,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    TipoRelatorioPipe,
    ModalExportacaoComponent,
    ModalInfoAgrupamentoComponent,
    DragDropDirective
  ],
  providers: [
    DataService,
    DelimitorPipe,
    ExtensaoFilePipe,
    TipoRelatorioPipe,
    ModalExportacaoObservable,
    ModalInfoAgrupamentoObservable
  ],
})
export class SharedModule { }
