<section
  class="modalExportacao hidden"
  [style.top.px]="currentTop"
>
  <div class="modalExportacaoContent">
    <header>
      <h2>{{ configuracaoModal.tipoRelatorio | tipoRelatorio }}</h2>
      <button
        type="button"
        class="secondary-action"
        (click)="hide()"
      >
        <img
          src="./../../../../assets/images/icon/close-button.svg"
          alt="fechar"
        >
      </button>
    </header>
    <div class="modal-content">
      <div class="mensagens">
        <span
          *ngIf="mensagem"
          class="message-text"
          role="alert"
          [class.is-success]="mensagem.tipo === MensagemTipo.SUCESSO"
          [class.is-error]="mensagem.tipo === MensagemTipo.ERRO"
          [class.is-warning]="mensagem.tipo === MensagemTipo.AVISO"
        >
          {{ mensagem.texto }}
        </span>
      </div>

      <section>
        <h2 class="center black">
          Clique no botão com a opção de exportação desejada, para exportar o
          {{ configuracaoModal.tipoRelatorio | tipoRelatorio }}
        </h2>
      </section>

      <div class="center btn_option">
        <button
          type="button"
          (click)="handleExportPDF()"
          mat-stroked-button
          color="primary"
        >
          <img
            src="./../../../../assets/images/icon/pdf.svg"
            alt="fechar"
            width="24"
            height="24"
          > <span>Exportar em PDF</span>
        </button>
        &nbsp; &nbsp;
        <button
          type="button"
          (click)="handleExportCSV()"
          mat-stroked-button
          color="primary"
        >
          <img
            src="./../../../../assets/images/icon/csv.svg"
            alt="fechar"
            width="24"
            height="24"
          > <span> Exportar em CSV </span>
        </button>
        <br />
      </div>
    </div>
    <footer class="action-group">
      <button
        type="button"
        class="secondary-action"
        (click)="hide()"
      >
        FECHAR
      </button>
    </footer>
  </div>
</section>
