<div class="autenticacao-form">

  <form
    #form="ngForm"
    (ngSubmit)="handleLogin()"
    autocomplete="off"
  >
    <div class="logo">
      <img alt="Logotipo">
    </div>

    <mat-form-field
      class="username"
      appearance="outline"
    >
      <mat-label>E-mail</mat-label>
      <input
        matInput
        type="text"
        id="usuario"
        name="usuario"
        maxlength="100"
        placeholder="E-mail"
        [(ngModel)]="autenticacao.usuario"
        #inputUsuario="ngModel"
        required
        autocomplete="off"
        [class.has-value]="autenticacao?.usuario"
        [class.has-error]="inputUsuario.touched && inputUsuario?.errors"
      />
      <mat-error
        *ngIf="inputUsuario.touched && inputUsuario?.errors"
        class="error-tooltip"
        role="alert"
      >
        Este campo é obrigatório!
      </mat-error>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="password"
    >
      <mat-label for="senha">Senha</mat-label>
      <input
        matInput
        type="password"
        [type]="hide ? 'password' : 'text'"
        id="senha"
        name="senha"
        [(ngModel)]="autenticacao.senha"
        #inputSenha="ngModel"
        maxlength="12"
        autocomplete="off"
        [class.has-value]="autenticacao?.senha"
        [class.has-error]="inputSenha.touched && inputSenha?.errors"
        required
      />
      <span
        class="material-icons-regular"
        (click)="hide = !hide"
        matSuffix
      >
        {{hide? 'visibility' : 'visibility_off'}}
      </span>
      <mat-error
        *ngIf="inputSenha.touched && inputSenha?.errors"
        class="error-tooltip"
        role="alert"
      >
        Este campo é obrigatório!
      </mat-error>
    </mat-form-field>

    <div class="inline-container">
      <div class="redefinir-senha">
        <a href="#">Esqueci minha senha</a>
      </div>

      <mat-checkbox
        id="lembrar-email"
        [(ngModel)]="lembrarEmailChecked"
        [ngModelOptions]="{standalone: true}"
      >Lembrar e-mail</mat-checkbox>
    </div>

    <button
      mat-flat-button
      id="btnLogin"
      type="submit"
      [disabled]="form.invalid"
      class="green-action"
      (click)="lembrarEmail()"
    >
      <div
        class="spinner"
        *ngIf="loaderService.loading | async"
      >
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      <span *ngIf="(loaderService.loading | async) === false">Entrar</span>
    </button>
    <div class="error-container">
      <span
        *ngIf="erro"
        class="error-tooltip"
        role="alert"
      >
        {{ erro }}
      </span>
    </div>

  </form>
  <div class="copyright">
    <span>© Copyright - Grupo Casas Bahia S/A - Todos os direitos reservados.</span>
  </div>
</div>
