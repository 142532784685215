import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import Autenticacao from '../shared/models/autenticacao-model';
import { environment } from './../../environments/environment';
import { DadosRotaAutenticada } from './../shared/models/dados-rota-autenticada';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  readonly TOKEN_STORAGE_KEY = 'token-comercial-sortimento';
  readonly USUARIO_STORAGE_KEY = 'usuario-comercial-sortimento';

  constructor(
    private http: HttpClient
  ) { }

  private salvarToken(token: any): void {
    sessionStorage.setItem(this.TOKEN_STORAGE_KEY, JSON.stringify(token));
  }

  private salvarUsuario(autenticacao: Autenticacao): void {
    const usuario: any = JSON.parse(JSON.stringify(autenticacao));
    delete usuario.senha;
    sessionStorage.setItem(this.USUARIO_STORAGE_KEY, JSON.stringify(usuario));
  }

  public getToken(): any | null {
    const tokenNaoParseado = sessionStorage.getItem(this.TOKEN_STORAGE_KEY);
    if (tokenNaoParseado) {
      return JSON.parse(tokenNaoParseado);
    }

    return null;
  }


  public getUsuario(): Autenticacao | null {
    const usuarioNaoParseado = sessionStorage.getItem(this.USUARIO_STORAGE_KEY);
    if (usuarioNaoParseado) {
      return JSON.parse(usuarioNaoParseado);
    }

    return null;
  }

  public logout(): void {
    sessionStorage.removeItem(this.TOKEN_STORAGE_KEY);
    sessionStorage.removeItem(this.USUARIO_STORAGE_KEY);
  }

  public async login(autenticacao: Autenticacao): Promise<void> {
    const url = `${environment.apiAddress}/oauth/token`;
    const headers = new HttpHeaders()
      .append('Authorization', environment.clientBasicAuth)
      .append('Content-Type', 'application/x-www-form-urlencoded');

    const body = `grant_type=password&scope=webapp&username=${encodeURIComponent(autenticacao.usuario)}&password=${encodeURIComponent(autenticacao.senha)}`;

    const token = await this.http.post<any>(url, body, { headers }).toPromise();

    if (token) {
      this.salvarToken(token);
      this.salvarUsuario(autenticacao);
    } else {
      throw new Error('Token inválido');
    }
  }

  public async temPermissao(dados: DadosRotaAutenticada): Promise<boolean> {
    const token = this.getToken();

    if (!dados || !dados.codigoFuncionalidade) {
      if (token) {
        return Promise.resolve(true);
      }
    }

    if (!token) {
      return Promise.resolve(false);
    }

    const url = `${environment.apiAddress}/autorizacoes/${dados.codigoFuncionalidade}`;
    return this.http.get(url).pipe<boolean>(map(() => true)).toPromise();
  }

}
