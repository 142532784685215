import { StatusAprovacaoEnum } from './enums/status-aprovacao.enum';
import { TipoAprovacao } from './enums/tipo-aprovacao.enum';
import FileModel from './file/file.model';

export class FluxoAprovacaoRequest {
  codigo?: number;
  codigoAgrupador?: number;
  uuid?: string;
  uuidAgrupador?: string;
  status?: StatusAprovacaoEnum;
  comentarios?: string;
  tipo?: TipoAprovacao;
  idMotivoReprovacao?: number;
  arquivos?: FileModel[] | null;
}
