import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfiguracaoModalInfoAgrupamento } from './modal-info-agrupamento.model';

@Injectable()
export class ModalInfoAgrupamentoObservable extends Subject<ConfiguracaoModalInfoAgrupamento> {

  constructor() {
    super();
  }

}
