import { Pipe, PipeTransform } from '@angular/core';
import { PrecoService } from './preco.service';

@Pipe({
  name: 'preco'
})
export class PrecoPipe implements PipeTransform {

  constructor(
    private precoService: PrecoService
  ) { }

  transform(value: number): string {
    return this.precoService.transform(value);
  }

}
