import { Categoria } from '../categoria.model';
import { FornecedorLn } from '../fornecedor-ln.model';
import { TipoFile } from './tipo-file';
import { TipoTemplate } from './tipo-template.enum';

export default class FileModel {


  id?: number;
  nome?: string;
  referencia?: string;
  data?: string;
  categoria?: Categoria;
  categoriaId?: number | null;
  fornecedor?: FornecedorLn;
  fornecedorId?: string | null;
  refresh?: number;
  tipoTemplate?: TipoTemplate | null;
  show = false;
  tamanho?: number;
  tipoFile?: TipoFile;
  fileBase64?: string;
  extensao?: string;

}
