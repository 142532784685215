import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoaderModule } from '../shared/loader/loader.module';
import { AuthGuardService } from '../autenticacao/autenticacao-guard';
import { CredenciaisHttpInterceptor } from '../autenticacao/credenciais-http.interceptor';
import { LinkService } from './link.service';
import { LinkPageComponent } from './link-page.component';
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    LoaderModule
  ],
  declarations: [
    LinkPageComponent
  ],
  providers: [
    AuthGuardService,
    LinkService,
    { provide: HTTP_INTERCEPTORS, useClass: CredenciaisHttpInterceptor, multi: true }
  ]
})
export class LinkModule { }
