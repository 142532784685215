export enum StatusAprovacaoEnum {
  REGISTRADO = 'REGISTRADO',
  EM_APROVACAO_NVL1 = 'EM_APROVACAO_NVL1',
  APROVADO_NVL1 = 'APROVADO_NVL1',
  REPROVADO_NVL1 = 'REPROVADO_NVL1',
  EM_APROVACAO_NVL2 = 'EM_APROVACAO_NVL2',
  APROVADO_NVL2 = 'APROVADO_NVL2',
  REPROVADO_NVL2 = 'REPROVADO_NVL2',
  RETIFICADO = 'RETIFICADO'
}
