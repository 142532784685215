import { Component } from '@angular/core';
import { MensagemTipo } from '../mensagens/mensagem-tipo.enum';
import { NewMensagensService } from './new-mensagens.service';

@Component({
  selector: 'app-new-mensagens',
  templateUrl: './new-mensagens.component.html',
  styleUrls: ['./new-mensagens.component.scss'],
})
export class NewMensagensComponent {
  MensagemTipo = MensagemTipo;
  constructor(public mensagensService: NewMensagensService) {}
  closeMessage(): void {
    this.mensagensService.limparMensagens();
  }
}
