import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../data.service';
import Autenticacao from '../../models/autenticacao-model';
import { version } from './../../../../../package.json';
import { AutenticacaoService } from './../../../autenticacao/autenticacao.service';
@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit, OnDestroy {
  public version: string = version;
  usuario: Autenticacao | null = null;
  dropdown: boolean;
  contador;
  parametros = [
    {
      nome: 'publicos',
      descricao: 'Públicos'
    },
    {
      nome: 'locais',
      descricao: 'Locais'
    },
    {
      nome: 'tamanhos',
      descricao: 'Tamanhos'
    },
    {
      nome: 'regioes-atendimentos',
      descricao: 'Regiões de Atendimento'
    },
    {
      nome: 'regioes-sortimentos',
      descricao: 'Regiões de Sortimento'
    }
  ];
  @ViewChild('menu') menu: ElementRef;
  contadorInbox = localStorage.getItem('contadorInbox') ? localStorage.getItem('contadorInbox') : 0;
  idVerificacao: any;
  pronto = false;
  subscription: Subscription;
  message: any;
  constructor(
    private router: Router,
    private autenticacaoService: AutenticacaoService,
    private messageService: DataService,
  ) {
    this.iniciarUsuario();
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {
    clearInterval(this.idVerificacao);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    const drops = document.getElementsByClassName('drop');
    Array.from(drops).forEach(elemento => {
      if (!elemento.contains(event.target)) {
        elemento.setAttribute('style', 'display:none');
      }
    });
  }
  private iniciarUsuario(): void {
    this.usuario = this.autenticacaoService.getUsuario();
  }
  ir(url: string, event?) {
    let prms: any = false;
    const drops = document.getElementsByClassName('drop');
    if (event) {
      Array.from(drops).forEach(elemento => {
        if (!elemento.contains(event.target)) {
          elemento.setAttribute('style', 'display:none');
        }
      });
    }
    this.parametros.forEach(parametro => {
      if (parametro.nome === url) {
        prms = parametro;
      }
    });
    if (prms) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/parametro'], { queryParams: { desc: prms.descricao, nome: prms.nome } }));
    } else {
      this.router.navigate([url]);
    }
  }
  abrirDropdown(id) {
    if (document.getElementById(id).style.display === 'block') {
      this.dropdown = false;
      document.getElementById(id).style.display = 'none';
    } else {
      this.dropdown = true;
      document.getElementById(id).style.display = 'block';
    }
  }
  ativo(link) {
    link = link.slice(0, -1);
    return this.router.url.startsWith(link);
  }
  naoAtivo(link) {
    link = link.slice(0, -1);
    return !this.router.url.startsWith(link);
  }
}
