import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  private data = new BehaviorSubject({});
  currentMessage = this.data.asObservable();

  changeMessage(message: {}) {
    this.data.next(message);
  }
}
