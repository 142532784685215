import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StatusAprovacaoEnum } from '../models/enums/status-aprovacao.enum';
import { FluxoAprovacaoRequest } from '../models/fluxo-aprovacao-request.model';
import ItensAprovacao from '../models/itens-aprovacao.model';

@Injectable({
  providedIn: 'root'
})
export class FluxoAprovacaoService {

  constructor(
    private http: HttpClient
  ) { }

  async listByStatusAprovacao(statusAprovacao: StatusAprovacaoEnum): Promise<ItensAprovacao> {
    const url = `${environment.apiAddress}/fluxo-aprovacao/${statusAprovacao}`;
    const retorno = await this.http.get<ItensAprovacao>(url).toPromise();
    return retorno;
  }

  async flow(fluxo: FluxoAprovacaoRequest): Promise<void> {
    const url = `${environment.apiAddress}/fluxo-aprovacao`;
    return await this.http.post<void>(url, fluxo).toPromise();
  }

  async cancelFlow(fluxo: FluxoAprovacaoRequest): Promise<void> {
    const url = `${environment.apiAddress}/fluxo-aprovacao/cancelar`;
    return await this.http.post<void>(url, fluxo).toPromise();
  }

}
