<app-modal-exportacao> </app-modal-exportacao>
<app-modal-info-agrupamento> </app-modal-info-agrupamento>
<app-menu-header></app-menu-header>

<div
  class="app-container"
  [style.background-image]="isActive('/home') ? 'url(/assets/images/arena/bg.png)' : 'unset'"
>
  <div class="containerExterno">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
