import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  quantidadeEmAndamento = 0;
  loading = new Subject<boolean>();

  start(): void {
    window.scroll(0, 0);
    this.startNotScroll();
  }

  startNotScroll(): void {
    document.getElementById('body').style.overflow = 'hidden';
    setTimeout(() => {
      this.quantidadeEmAndamento++;
      this.loading.next(true);
    });
  }

  stop(): void {
    document.getElementById('body').style.overflow = '';
    document.getElementById('body').style.overflowY = 'scroll !important';
    setTimeout(() => {
      if (this.quantidadeEmAndamento > 0) {
        this.quantidadeEmAndamento--;
      }

      if (this.quantidadeEmAndamento === 0) {
        this.loading.next(false);
      }
    });
  }

}
