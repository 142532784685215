<app-breadcrumb tituloPagina="Para Aprovar"></app-breadcrumb>
<app-new-mensagens></app-new-mensagens>
<div>
  <div class="titulo" container-wrapper>
    <h1>Para Aprovar</h1>
    <h4>
      Você tem <span>{{ qtde }} {{ qtde > 1 ? "cálculos" : "cálculo" }}</span>
      {{ qtde > 1 ? "disponíveis" : "disponível" }} para aprovação
      <a *ngIf="uuid && qtde > 1" (click)="handleShow()"
        >Ver {{ show ? "menos" : "mais" }}</a
      >
    </h4>
  </div>
  <div></div>
</div>

<app-calculo-item-form
  [itensAprovacao]="itensAprovacao"
  [statusAprovacao]="statusAprovacao"
>
</app-calculo-item-form>
