import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import Mensagem from '../../mensagens/mensagem-model';
import { MensagemTipo } from '../../mensagens/mensagem-tipo.enum';
import { ConfiguracaoModalInfoAgrupamento } from './modal-info-agrupamento.model';
import { ModalInfoAgrupamentoObservable } from './modal-info-agrupamento.observable';

@Component({
  selector: 'app-modal-info-agrupamento',
  templateUrl: './modal-info-agrupamento.component.html',
  styleUrls: ['./modal-info-agrupamento.component.scss'],
})
export class ModalInfoAgrupamentoComponent implements OnInit, OnDestroy {
  configuracaoModal = new ConfiguracaoModalInfoAgrupamento();

  subscriptions = new Subscription();

  mensagem = new Mensagem();

  MensagemTipo = MensagemTipo;
  currentTop: number;

  constructor(
    private $modalInfoAgrupamento: ModalInfoAgrupamentoObservable,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.$modalInfoAgrupamento.subscribe((configuracao) => {
        if (configuracao) {
          this.configuracaoModal = configuracao;
          this.show();
        }
      })
    );
  }

  disableScrolling() {
    let x = window.scrollX;
    let y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y); };
  }

  enableScrolling() {
    window.onscroll = function () { };
  }

  show(): void {
    this.mensagem = new Mensagem();
    const modal = document.querySelector('section.modalInfoAgrupamento');
    const body = document.querySelector('body');
    modal.classList.remove('hidden');
    modal.classList.add('show');
    this.currentTop = document.documentElement.scrollTop;
    console.log(document.documentElement.scrollTop);
    body.classList.add('stop-scrolling');
  }

  hide(): void {
    const modal = document.querySelector('section.modalInfoAgrupamento');
    const body = document.querySelector('body');
    modal.classList.remove('show');
    modal.classList.add('hidden');
    body.classList.remove('stop-scrolling');

    console.log(document.documentElement.scrollTop);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
