<section
  class="modalInfoAgrupamento hidden"
  [style.top.px]="currentTop"
>
  <div class="modalInfoAgrupamentoContent">
    <header>
      <h2>{{ configuracaoModal.title }}</h2>
      <button
        type="button"
        class="secondary-action"
        (click)="hide()"
      >
        <img
          src="./../../../../assets/images/icon/close-button.svg"
          alt="fechar"
        >
      </button>
    </header>
    <div class="modal-content">
      <div
        class="center option"
        *ngFor="let item of configuracaoModal.itens; let iItem = index"
      >
        <mat-checkbox
          id="checkbox_{{ item.id }}_{{ iItem }}"
          name="checkbox_{{ item.id }}_{{ iItem }}"
          color="primary"
          disabled
          [checked]="item.selecionado"
        >
          {{ item.descricao }}
        </mat-checkbox>
      </div>
    </div>
    <footer class="action-group">
      <button
        type="button"
        class="secondary-action"
        (click)="hide()"
      >
        FECHAR
      </button>
    </footer>
  </div>
</section>
