import { Pipe, PipeTransform } from '@angular/core';
import { TipoModalidade } from '../enums/tipo-modalidade.enum';


@Pipe({
  name: 'modalidade'
})
export class ModalidadePipe implements PipeTransform {

  transform(modalidade: TipoModalidade): string {
    switch (modalidade) {
      case TipoModalidade.FAMILIA_PRODUTO:
        return 'Família de Produto';
      case TipoModalidade.SKU:
        return 'SKU';
    }
    return '--';
  }

}
