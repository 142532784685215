import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from './shared/loader/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade', [
      transition(
        ':enter', [
        style({ opacity: 0.3 }),
        animate('0.5s ease-out', style({ opacity: 0.3 }))
      ]),
      transition(
        ':leave', [
        style({ opacity: 0.3 }),
        animate('1s ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class AppComponent implements OnInit {
  time;

  constructor(
    public loaderService: LoaderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.inactivityTime();
  }
  inactivityTime(): void {
    window.onload = () => this.resetTimer();
    // DOM Events
    document.onmousemove = () => this.resetTimer();
    document.onkeypress = () => this.resetTimer();
  }

  resetTimer() {
    clearTimeout(this.time);
    this.time = setTimeout(() => this.logout(), 600000);
  }

  logout() {
    this.router.navigate(['auth']);
  }
}
