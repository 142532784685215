import { Component, Input } from '@angular/core';
import Breadcrumb from '../../models/breadcrumb-model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  @Input() links: [Breadcrumb];
  @Input() tituloPagina: string;
  home = { titulo: 'Home', url: ['/home']} as Breadcrumb;

}
