import { Pipe, PipeTransform } from '@angular/core';
import { TipoFile } from './tipo-file';


@Pipe({
  name: 'extensaoFile'
})
export class ExtensaoFilePipe implements PipeTransform {

  transform(status: TipoFile): string {
    switch (status) {
      case TipoFile.PDF:
        return 'pdf';
      case TipoFile.PNG:
        return 'png';
      case TipoFile.DOC:
        return 'doc';
      case TipoFile.DOCX:
        return 'docx';
      case TipoFile.JPEG:
        return 'jpeg';
      case TipoFile.JPG:
        return 'jpg';
    }
    return 'pdf';
  }

}
