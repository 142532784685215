import { Pipe, PipeTransform } from '@angular/core';
import { TipoRelatorio } from './../modal-exportacao/tipo-relatorio.enum';

@Pipe({
  name: 'tipoRelatorio',
})
export class TipoRelatorioPipe implements PipeTransform {
  transform(tipoRelatorio: TipoRelatorio): string {
    switch (tipoRelatorio) {
      case TipoRelatorio.APURACAO_POR_VENDA:
        return 'Relatório da Apuração por Venda';

      case TipoRelatorio.APURACAO_POR_RECEBIMENTO:
        return 'Relatório da Apuração por Recebimento';
    }
  }
}
