import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfiguracaoModalExportacao } from './modal-exportacao.model';

@Injectable()
export class ModalExportacaoObservable extends Subject<ConfiguracaoModalExportacao> {

  constructor() {
    super();
  }

}
