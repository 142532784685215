<div
  class="calculo w-100"
  *ngFor="let item of itensAprovacao.calculos; let i = index"
  id="calculo_{{ item.identificador }}"
>
  <div class="calculo-header" [class.isTitleReprovacao]="!isFluxoAprovacao">
    <h2
      class="w-20"
      *ngIf="!(item.identificadores && item.identificadores.length > 1)"
    >
      {{ item.identificador }}
    </h2>
    <h2 *ngIf="item.identificadores && item.identificadores.length > 1">
      Identificador(es):
      <a class="link-modal" (click)="abrirModalIdentificadores(item)"
        >({{ item.identificadores!.length }}) Visualizar</a
      >
    </h2>
    <div>
      <h4>
        <span
          ><strong>Período:</strong> {{ item.dataInicial }} à
          {{ item.dataFinal }}</span
        >
        <span *ngIf="item.dataInicialAgendamento"
          ><strong>{{ item.dataInicialAgendamento ? "Agenda: " : "" }}</strong>
          {{ item.dataInicialAgendamento }}
          {{ item.dataInicialAgendamento ? " à " : "" }}
          {{ item.dataFinalAgendamento }}
        </span>
      </h4>
      <h4 *ngIf="!(item.marcas && item.marcas.length > 1)">
        <span
          ><strong>Marca:</strong> {{ item.marca.id }} -
          {{ item.marca.descricao }}</span
        >
        <span
          ><strong>Categoria:</strong> {{ item.categoria.id }} -
          {{ item.categoria.descricao }}</span
        >
      </h4>
      <h4
        class="col-4 p-t-xs align-end"
        *ngIf="item.marcas && item.marcas.length > 1"
      >
        <span
          ><strong>Marca(s): </strong>
          <a class="link-modal" (click)="abrirModalMarcas(item)"
            >({{ item.marcas!.length }}) Visualizar</a
          >
        </span>
        <span
          ><strong>Categoria:</strong> {{ item.categoria.id }} -
          {{ item.categoria.descricao }}</span
        >
      </h4>
    </div>
  </div>

  <ng-container>
    <div
      class="card"
      id="card_{{ item.identificador }}"
      [class.isAprovacao]="isFluxoAprovacao"
      [class.isReprovacao]="!isFluxoAprovacao"
    >
      <div
        [class.reprovado]="item?.status === 'REPROVADO_NVL2'"
        id="fluxo_reprovado{{ item.identificador }}"
        class="hidden flow isReprovacao row"
        container-wrapper
      >
        <div container-size="xsmall" class="container-resposta">
          <h2 class="resposta">CÁLCULO REPROVADO</h2>
        </div>
        <div container-size="xlarge">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Selecione o motivo da reprovação...</mat-label>
              <mat-select
                id="idMotivoReprovacao"
                name="idMotivoReprovacao"
                [(ngModel)]="item.idMotivoReprovacao"
              >
                <mat-option
                  *ngFor="let result of itensReprovacao.motivos"
                  [value]="result.id"
                >
                  {{ result.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label
                >Descreva o motivo da reprovação para este cálculo</mat-label
              >
              <textarea
                matInput
                rows="5"
                [(ngModel)]="item.motivo"
                #configuracaoModalMotivo="ngModel"
              ></textarea>
            </mat-form-field>
          </div>

          <div>
            <h4>
              {{ item.idMotivoReprovacao == 1 ? "* " : "" }}
              Faça <strong>upload</strong> dos arquivos de evidência dessa
              reprovação.
            </h4>
            <div>
              <div class="agrupadorCard">
                <div
                  class="upload"
                  appDragDrop
                  (fileDropped)="changeFile(item.arquivosReprovados, $event)"
                >
                  <button
                    type="submit"
                    (click)="inputFile.click()"
                    title="Selecionar arquivo"
                    mat-stroked-button
                    color="primary"
                  >
                    Selecionar arquivo...
                    <mat-icon> cloud_upload </mat-icon>
                  </button>
                  <span> ou arraste aqui para fazer o upload </span>
                  <input
                    id="file"
                    #inputFile
                    style="visibility: hidden"
                    name="file"
                    type="file"
                    multiple
                    placeholder="Enviar arquivos"
                    (change)="changeFile(item.arquivosReprovados, $event)"
                    (click)="onInputClick($event)"
                  />
                </div>
              </div>
              <h4 class="right">
                Limite máximo de 10 arquivos. 10MB por arquivo (PDF, Word, JPG,
                JPEG ou PNG)
              </h4>
              <div class="row">
                <div class="agrupadorCard">
                  <div class="row">
                    <div
                      class="col-6 column {{ i % 2 === 0 ? 'l' : 'r' }}"
                      *ngFor="
                        let arquivo of item.arquivosReprovados;
                        let i = index
                      "
                    >
                      <mat-card
                        class="col-11 p-s m-b-l"
                        *ngIf="arquivo && arquivo.nome"
                      >
                        <div class="col-8">
                          <div
                            *ngIf="arquivo.tipoFile"
                            class="icon {{ arquivo.tipoFile | extensaoFile }}"
                          ></div>
                          <div>
                            <p matTooltip="{{ arquivo.nome }}">
                              {{ arquivo.nome ? (arquivo.nome | limit) : "3" }}

                              <span>{{ arquivo.tamanho }} MB</span>
                            </p>
                          </div>
                        </div>
                        <div class="col-4">
                          <button
                            mat-button
                            (click)="
                              removerArquivo(item.arquivosReprovados, arquivo)
                            "
                            class="button_red"
                          >
                            REMOVER <mat-icon>remove_circle</mat-icon>
                          </button>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        [class.aprovado]="item?.status === 'APROVADO_NVL2'"
        id="fluxo_aprovado{{ item.identificador }}"
        class="hidden flow"
        container-wrapper
      >
        <div container-size="small" class="title">
          <h2 class="resposta">CÁLCULO APROVADO</h2>
        </div>
        <div container-size="xxsmall" class="btn">
          <button
            class="secondary-action-purple"
            (click)="onUndoCalculo(item)"
            *ngIf="timers.get(item.uuid) > 0"
            type="button"
          >
            Desfazer ({{ timers.get(item.uuid) }}s)
          </button>
        </div>
      </div>

      <div container-wrapper id="{{ item.identificador }}">
        <div container-size="xlarge" container-wrapper class="column">
          <div container-size="small">
            <div *ngIf="item.quantidadeNotas && item.quantidadeNotas > 0">
              <h2>NOTAS</h2>
              <ul element-wrapper>
                <li element-size="medium">
                  <span>Qtde. Itens:</span>
                </li>
                <li element-size="medium">
                  <span class="column_valor">{{ item.quantidadeNotas }}</span>
                </li>
              </ul>
              <ul element-wrapper>
                <li element-size="medium">
                  <span>Valor Bruto:</span>
                </li>
                <li element-size="medium">
                  <span class="column_valor">{{
                    item.valorBrutoNotas | preco
                  }}</span>
                </li>
              </ul>
            </div>
            <div
              *ngIf="
                item.quantidadeAgendamento && item.quantidadeAgendamento > 0
              "
            >
              <h2 class="title_column">AGENDA</h2>
              <ul element-wrapper>
                <li element-size="medium">
                  <span>Qtde. Itens:</span>
                </li>
                <li element-size="medium">
                  <span class="column_valor">{{
                    item.quantidadeAgendamento
                  }}</span>
                </li>
              </ul>
              <ul element-wrapper>
                <li element-size="medium">
                  <span>Valor Bruto:</span>
                </li>
                <li element-size="medium">
                  <span class="column_valor">{{
                    item.valorBrutoAgendamento | preco
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div container-size="small">
            <h2 class="title_column">IMPOSTOS</h2>
            <h4>Notas</h4>
            <ul element-wrapper>
              <li element-size="medium">
                <span>ICMS:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorICMSRecebimento | preco
                }}</span>
              </li>
            </ul>
            <ul element-wrapper>
              <li element-size="medium">
                <span>PIS-COFINS:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorPISCOFINSRecebimento | preco
                }}</span>
              </li>
            </ul>
            <ul element-wrapper>
              <li element-size="medium">
                <span>IPI:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorIPIRecebimento | preco
                }}</span>
              </li>
            </ul>
            <h4>Agenda</h4>
            <ul element-wrapper>
              <li element-size="medium">
                <span>ICMS:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorICMSAgendamento | preco
                }}</span>
              </li>
            </ul>
            <ul element-wrapper>
              <li element-size="medium">
                <span>PIS-COFINS:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorPISCOFINSAgendamento | preco
                }}</span>
              </li>
            </ul>
            <ul element-wrapper>
              <li element-size="medium">
                <span>IPI:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorIPIAgendamento | preco
                }}</span>
              </li>
            </ul>
          </div>
          <div container-size="small">
            <h2 class="title_column">VALOR LÍQUIDO</h2>
            <h4>Notas</h4>
            <ul element-wrapper>
              <li element-size="xsmall">
                <span>NET:</span>
              </li>
              <li element-size="xlarge">
                <span class="column_valor">{{
                  item.valorNETRecebimento | preco
                }}</span>
              </li>
            </ul>
            <h4>Agenda</h4>
            <ul element-wrapper>
              <li element-size="xsmall">
                <span>NET:</span>
              </li>
              <li element-size="xlarge">
                <span class="column_valor">{{
                  item.valorNETAgendamento | preco
                }}</span>
              </li>
            </ul>
            <ul element-wrapper class="align-end">
              <li element-size="xsmall">
                <span class="negrito">TOTAL NET:</span>
              </li>
              <li element-size="xlarge">
                <span class="column_valor negrito">{{
                  obterTotalNET(item) | preco
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div container-size="small" class="verba_gerada column">
          <h2>VERBA GERADA</h2>
          <ul element-wrapper>
            <ng-container
              *ngIf="
                item.valorVerbaGeradaRecebimentoValorBruto &&
                item.valorVerbaGeradaRecebimentoValorBruto > 0
              "
            >
              <li element-size="medium">
                <span>Notas (VB):</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorVerbaGeradaRecebimentoValorBruto | preco
                }}</span>
              </li>
            </ng-container>
            <ng-container
              *ngIf="
                item.valorVerbaGeradaRecebimentoValorLiquido &&
                item.valorVerbaGeradaRecebimentoValorLiquido > 0
              "
            >
              <li element-size="medium">
                <span>Notas (VL):</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorVerbaGeradaRecebimentoValorLiquido | preco
                }}</span>
              </li>
            </ng-container>
            <ng-container
              *ngIf="
                item.valorVerbaGeradaAgendamentoValorBruto &&
                item.valorVerbaGeradaAgendamentoValorBruto > 0
              "
            >
              <li element-size="medium">
                <span>Agenda (VB):</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorVerbaGeradaAgendamentoValorBruto | preco
                }}</span>
              </li>
            </ng-container>
            <ng-container
              *ngIf="
                item.valorVerbaGeradaAgendamentoValorLiquido &&
                item.valorVerbaGeradaAgendamentoValorLiquido > 0
              "
            >
              <li element-size="medium">
                <span>Agenda (VL):</span>
              </li>
              <li element-size="medium">
                <span class="column_valor">{{
                  item.valorVerbaGeradaAgendamentoValorLiquido | preco
                }}</span>
              </li>
            </ng-container>
            <ng-container
              *ngIf="
                item.valorVerbaGeradaRecebimentoValorBruto &&
                item.valorVerbaGeradaRecebimentoValorLiquido &&
                item.valorVerbaGeradaAgendamentoValorBruto &&
                item.valorVerbaGeradaAgendamentoValorLiquido
              "
            >
              <li element-size="medium">
                <span>Total Gerado no Período:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor column_valor_verba_gerada">{{
                  obterTotalVerbaGerada(item) | preco
                }}</span>
              </li>
            </ng-container>
            <ng-container *ngIf="item.valorVerbaGeradaDiferencaAgendaAnterior">
              <li element-size="medium">
                <span>GAP:</span>
              </li>
              <li element-size="medium">
                <span
                  [class.negativo]="
                    item.valorVerbaGeradaDiferencaAgendaAnterior < 0
                  "
                  [class.column_valor_verba_gerada]="
                    item.valorVerbaGeradaDiferencaAgendaAnterior > 0
                  "
                  class="column_valor"
                >
                  {{ item.valorVerbaGeradaDiferencaAgendaAnterior | preco }}
                </span>
              </li>
            </ng-container>
            <ng-container>
              <li element-size="medium">
                <span class="negrito">TOTAL:</span>
              </li>
              <li element-size="medium">
                <span
                  class="column_valor column_valor_verba_gerada total negrito"
                  >{{ item.totalVerbaGerada | preco }}</span
                >
              </li>
            </ng-container>
          </ul>
        </div>

        <div class="memoria-calculo-mobile">
          <button
            class="secondary-action"
            (click)="onViewCalculationMemoryCalculo(item)"
            type="button"
            *ngIf="isFluxoAprovacao"
          >
            Memória de Cálculo
            <span class="material-icons"> file_download </span>
          </button>

          <div *ngIf="!isFluxoAprovacao" class="right">
            <h1>CÁLCULO REPROVADO</h1>
          </div>
        </div>
      </div>
    </div>

    <div container-wrapper class="cardFooterControls">
      <div
        container-size="
                  small"
        class="actions left"
      >
        <button
          class="secondary-action"
          (click)="onViewCalculationMemoryCalculo(item)"
          type="button"
          *ngIf="isFluxoAprovacao"
        >
          Memória de Cálculo <span class="material-icons"> file_download </span>
        </button>
      </div>
      <div *ngIf="!isFluxoAprovacao" class="right reprovado">
        <h1>CÁLCULO REPROVADO</h1>
      </div>
      <div
        id="btn{{ item.identificador }}"
        class="actions"
        *ngIf="isFluxoAprovacao"
      >
        <button
          class="secondary-action-red"
          (click)="onReapprovalCalculo(item)"
          type="button"
        >
          Reprovar
        </button>
        <button
          class="main-action"
          (click)="onApprovalCalculo(item)"
          type="button"
        >
          Aprovar
        </button>
      </div>
      <div
        id="btn-reprovar{{ item.identificador }}"
        *ngIf="item?.status === 'REPROVADO_NVL2'"
        class="actions"
      >
        <button
          class="secondary-action-gray"
          (click)="onCancelCalculo(item)"
          mat-stroked-button
          type="button"
        >
          Cancelar
        </button>

        <button
          class="secondary-action-red-filled"
          (click)="onSendReprovalCalculo(item)"
          type="button"
          [disabled]="
            !item.motivo ||
            item.motivo.trim().length === 0 ||
            !item.idMotivoReprovacao ||
            (item.idMotivoReprovacao == 1 &&
              item.arquivosReprovados.length == 0)
          "
        >
          Enviar
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div
  class="calculo w-100"
  id="campanha_{{ item.identificador }}"
  *ngFor="let item of itensAprovacao.campanhas; let i = index"
>
  <div
    class="calculo-header"
    [class.isTitleReprovacao]="!isFluxoAprovacao"
    container-wrapper
  >
    <h2 container-size="small">{{ item.identificador }}</h2>
    <div>
      <h4>
        <span
          ><strong>Período:</strong> {{ item.dataInicio }} à
          {{ item.dataFim }}</span
        >
        <span *ngIf="item.categoria"
          ><strong>Categoria:</strong> {{ item.categoria.id }} -
          {{ item.categoria.descricao }}</span
        >
      </h4>
    </div>
  </div>

  <ng-container>
    <div
      class="card"
      id="card_{{ item.identificador }}"
      [class.isAprovacao]="isFluxoAprovacao"
      [class.isReprovacao]="!isFluxoAprovacao"
    >
      <div
        [class.reprovado]="item?.statusAprovacao === 'REPROVADO_NVL2'"
        id="fluxo_reprovado{{ item.identificador }}"
        class="hidden flow isReprovacao"
        container-wrapper
      >
        <div container-size="xsmall" class="container-resposta">
          <h2 class="resposta">AÇÃO REPROVADA</h2>
        </div>
        <div container-size="xlarge">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Selecione o motivo da reprovação...</mat-label>
              <mat-select
                id="idMotivoReprovacao"
                name="idMotivoReprovacao"
                [(ngModel)]="item.idMotivoReprovacao"
              >
                <mat-option
                  *ngFor="let result of itensReprovacao.motivos"
                  [value]="result.id"
                >
                  {{ result.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label
                >Descreva o motivo da reprovação para esta campanha</mat-label
              >
              <textarea
                matInput
                rows="5"
                [(ngModel)]="item.motivo"
                #configuracaoModalMotivo="ngModel"
              ></textarea>
            </mat-form-field>
          </div>

          <div>
            <h4>
              {{ item.idMotivoReprovacao == 1 ? "* " : "" }}
              Faça <strong>upload</strong> dos arquivos de evidência dessa
              reprovação.
            </h4>
            <div>
              <div class="agrupadorCard">
                <div
                  class="upload"
                  appDragDrop
                  (fileDropped)="changeFile(item.arquivosReprovados, $event)"
                >
                  <button
                    type="submit"
                    (click)="inputFile.click()"
                    title="Selecionar arquivo"
                    mat-stroked-button
                    color="primary"
                  >
                    Selecionar arquivo...
                    <mat-icon> cloud_upload </mat-icon>
                  </button>
                  <span> ou arraste aqui para fazer o upload </span>
                  <input
                    id="file"
                    #inputFile
                    style="visibility: hidden"
                    name="file"
                    type="file"
                    multiple
                    placeholder="Enviar arquivos"
                    (change)="changeFile(item.arquivosReprovados, $event)"
                    (click)="onInputClick($event)"
                  />
                </div>
              </div>
              <h4 class="right">
                Limite máximo de 10 arquivos. 10MB por arquivo (PDF, Word, JPG,
                JPEG ou PNG)
              </h4>
              <div class="row">
                <div class="agrupadorCard">
                  <div class="row">
                    <div
                      class="col-6 column {{ i % 2 === 0 ? 'l' : 'r' }}"
                      *ngFor="
                        let arquivo of item.arquivosReprovados;
                        let i = index
                      "
                    >
                      <mat-card
                        class="col-11 p-s m-b-l"
                        *ngIf="arquivo && arquivo.nome"
                      >
                        <div class="col-8">
                          <div
                            *ngIf="arquivo.tipoFile"
                            class="icon {{ arquivo.tipoFile | extensaoFile }}"
                          ></div>
                          <div>
                            <p matTooltip="{{ arquivo.nome }}">
                              {{
                                arquivo.nome ? (arquivo.nome | limit: "10") : ""
                              }}

                              <span>{{ arquivo.tamanho }} MB</span>
                            </p>
                          </div>
                        </div>
                        <div class="col-4">
                          <button
                            mat-button
                            (click)="
                              removerArquivo(item.arquivosReprovados, arquivo)
                            "
                            class="button_red"
                          >
                            REMOVER <mat-icon>remove_circle</mat-icon>
                          </button>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        [class.aprovado]="item?.statusAprovacao === 'APROVADO_NVL2'"
        id="fluxo_aprovado{{ item.identificador }}"
        class="hidden flow"
        container-wrapper
      >
        <div container-size="small" class="title">
          <h2 class="resposta">AÇÃO APROVADA</h2>
        </div>
        <div container-size="xxsmall" class="btn">
          <button
            class="secondary-action-gray"
            (click)="onUndoCampanha(item)"
            type="button"
            *ngIf="timers.get(item.uuid) > 0"
          >
            Desfazer ({{ timers.get(item.uuid) }}s)
          </button>
        </div>
      </div>

      <div container-wrapper id="{{ item.identificador }}">
        <div container-size="xlarge" container-wrapper class="column">
          <div container-size="full">
            <div>
              <h2>DADOS DA AÇÃO</h2>
              <ul element-wrapper>
                <li element-size="small">
                  <span>Condição de Bonificação</span>
                </li>
                <li element-size="large">
                  <span class="column_valor">{{
                    item.tipoBonificacao.description
                  }}</span>
                </li>
              </ul>
              <ul element-wrapper>
                <li element-size="small">
                  <span>Modalidade:</span>
                </li>
                <li element-size="large">
                  <span class="column_valor">{{
                    item.modalidade | modalidade
                  }}</span>
                </li>
              </ul>
              <ul element-wrapper>
                <li element-size="small">
                  <span>Tipo Quantidade:</span>
                </li>
                <li element-size="large">
                  <span class="column_valor">{{
                    item.tipoQuantidade | tipoQuantidade
                  }}</span>
                </li>
              </ul>
              <ul element-wrapper>
                <li element-size="small">
                  <span>Locais Participantes:</span>
                </li>
                <li element-size="large">
                  <span class="column_valor">
                    {{
                      item.locaisParticipantesOnLine &&
                      item.locaisParticipantesLojaFisica
                        ? "On-line | Lojas Físicas"
                        : !item.locaisParticipantesOnLine
                        ? "Lojas Físicas"
                        : "On-line"
                    }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div container-size="small" class="verba_gerada column">
          <h2>TOTAL</h2>
          <ul element-wrapper>
            <ng-container
              *ngIf="item.totalNegociado && item.totalNegociado > 0"
            >
              <li element-size="medium">
                <span class="negrito">Total Negociado:</span>
              </li>
              <li element-size="medium">
                <span class="column_valor negrito">{{
                  item.totalNegociado | preco
                }}</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="memoria-calculo-mobile">
          <button
            class="secondary-action"
            (click)="onViewCalculationMemoryCalculo(item)"
            type="button"
            *ngIf="isFluxoAprovacao"
          >
            Memória de Cálculo
            <span class="material-icons"> file_download </span>
          </button>
          <div *ngIf="!isFluxoAprovacao" class="right">
            <h1>CÁLCULO REPROVADO</h1>
          </div>
        </div>
      </div>
    </div>

    <div container-wrapper class="cardFooterControls">
      <div container-size="small" class="actions left">
        <button
          class="secondary-action"
          (click)="onViewCalculationMemoryCampanha(item)"
          type="button"
          *ngIf="isFluxoAprovacao"
        >
          Memória de Cálculo <span class="material-icons"> file_download </span>
        </button>
      </div>
      <div *ngIf="!isFluxoAprovacao" class="right reprovado">
        <h1>AÇÃO REPROVADA</h1>
      </div>
      <div
        id="btn{{ item.identificador }}"
        class="actions"
        *ngIf="isFluxoAprovacao"
      >
        <button
          class="secondary-action-red"
          (click)="onReapprovalCampanha(item)"
          type="button"
        >
          Reprovar
        </button>
        <button
          class="main-action"
          (click)="onApprovalCampanha(item)"
          type="button"
        >
          Aprovar
        </button>
      </div>
      <div
        id="btn-reprovar{{ item.identificador }}"
        class="actions"
        *ngIf="item?.statusAprovacao === 'REPROVADO_NVL2'"
      >
        <button
          class="secondary-action-gray"
          (click)="onCancelCampanha(item)"
          mat-stroked-button
          type="button"
        >
          Cancelar
        </button>

        <button
          class="secondary-action-red-filled"
          (click)="onSendReprovalCampanha(item)"
          type="button"
          [disabled]="
            !item.motivo ||
            item.motivo.trim().length === 0 ||
            !item.idMotivoReprovacao ||
            (item.idMotivoReprovacao == 1 &&
              item.arquivosReprovados.length == 0)
          "
        >
          Enviar
        </button>
      </div>
    </div>
  </ng-container>
</div>

<!-- Verba extra -->
<div
  class="calculo w-100"
  id="verba_extra_{{ item.identificador }}"
  *ngFor="let item of itensAprovacao.verbasExtra; let i = index"
>
  <div
    class="calculo-header"
    [class.isTitleReprovacao]="!isFluxoAprovacao"
    container-wrapper
  >
    <h2 container-size="small">VERBA EXTRA</h2>
    <div>
      <h4>
        <span><strong>Data:</strong> {{ item.data }} </span>
        <span *ngIf="item.categoria"
          ><strong>Categoria:</strong> {{ item.categoria.id }} -
          {{ item.categoria.descricao }}</span
        >
      </h4>
    </div>
  </div>

  <ng-container>
    <div
      class="card verba-extra"
      id="card_{{ item.identificador }}"
      [class.isAprovacao]="isFluxoAprovacao"
      [class.isReprovacao]="!isFluxoAprovacao"
    >
      <div
        [class.reprovado]="item?.statusAprovacao === 'REPROVADO_NVL2'"
        id="fluxo_reprovado{{ item.identificador }}"
        class="hidden flow isReprovacao"
        container-wrapper
      >
        <div container-size="xsmall" class="container-resposta">
          <h2 class="resposta">VERBA EXTRA REPROVADA</h2>
        </div>
        <div container-size="xlarge">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Selecione o motivo da reprovação...</mat-label>
              <mat-select
                id="idMotivoReprovacao"
                name="idMotivoReprovacao"
                [(ngModel)]="item.idMotivoReprovacao"
              >
                <mat-option
                  *ngFor="let result of itensReprovacao.motivos"
                  [value]="result.id"
                >
                  {{ result.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-label
                >Descreva o motivo da reprovação para esta verba
                extra</mat-label
              >
              <textarea
                matInput
                rows="5"
                [(ngModel)]="item.motivo"
                #configuracaoModalMotivo="ngModel"
              ></textarea>
            </mat-form-field>
          </div>

          <div>
            <h4>
              {{ item.idMotivoReprovacao == 1 ? "* " : "" }}
              Faça <strong>upload</strong> dos arquivos de evidência dessa
              reprovação.
            </h4>
            <div>
              <div class="agrupadorCard">
                <div
                  class="upload"
                  appDragDrop
                  (fileDropped)="changeFile(item.arquivosReprovados, $event)"
                >
                  <button
                    type="submit"
                    (click)="inputFile.click()"
                    title="Selecionar arquivo"
                    mat-stroked-button
                    color="primary"
                  >
                    Selecionar arquivo...
                    <mat-icon> cloud_upload </mat-icon>
                  </button>
                  <span> ou arraste aqui para fazer o upload </span>
                  <input
                    id="file"
                    #inputFile
                    style="visibility: hidden"
                    name="file"
                    type="file"
                    multiple
                    placeholder="Enviar arquivos"
                    (change)="changeFile(item.arquivosReprovados, $event)"
                    (click)="onInputClick($event)"
                  />
                </div>
              </div>
              <h4 class="right">
                Limite máximo de 10 arquivos. 10MB por arquivo (PDF, Word, JPG,
                JPEG ou PNG)
              </h4>
              <div class="row">
                <div class="agrupadorCard">
                  <div class="row">
                    <div
                      class="col-6 col-arquivo {{ i % 2 === 0 ? 'l' : 'r' }}"
                      *ngFor="
                        let arquivo of item.arquivosReprovados;
                        let i = index
                      "
                    >
                      <mat-card
                        class="col-11 p-s m-b-l"
                        *ngIf="arquivo && arquivo.nome"
                      >
                        <div class="col-8">
                          <div
                            *ngIf="arquivo.tipoFile"
                            class="icon {{ arquivo.tipoFile | extensaoFile }}"
                          ></div>
                          <div>
                            <p title="{{ arquivo.nome }}">
                              {{
                                arquivo.nome ? (arquivo.nome | limit: "10") : ""
                              }}

                              <span>{{ arquivo.tamanho }} MB</span>
                            </p>
                          </div>
                        </div>
                        <div class="col-4">
                          <button
                            mat-button
                            (click)="
                              removerArquivo(item.arquivosReprovados, arquivo)
                            "
                            class="button_red"
                          >
                            REMOVER <mat-icon>remove_circle</mat-icon>
                          </button>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        [class.aprovado]="item?.statusAprovacao === 'APROVADO_NVL2'"
        id="fluxo_aprovado{{ item.identificador }}"
        class="hidden flow"
        container-wrapper
      >
        <div container-size="small" class="title">
          <h2 class="resposta">VERBA EXTRA APROVADA</h2>
        </div>
        <div container-size="xxsmall" class="btn">
          <button
            class="secondary-action-gray"
            (click)="onUndoVerbaExtra(item)"
            type="button"
            *ngIf="timers.get(item.uuid) > 0"
          >
            Desfazer ({{ timers.get(item.uuid) }}s)
          </button>
        </div>
      </div>

      <div container-wrapper id="{{ item.identificador }}">
        <div container-size="xlarge" container-wrapper class="column">
          <div container-size="full">
            <div>
              <h2>DADOS DA VERBA EXTRA</h2>
              <ul element-wrapper>
                <li element-size="small">
                  <span>Descrição:</span>
                </li>
                <li element-size="large">
                  <span class="column_valor">{{ item.descricao }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div container-size="small" class="verba_gerada column">
          <h2>TOTAL</h2>
          <ul element-wrapper>
            <li element-size="medium">
              <span class="negrito">Total Negociado:</span>
            </li>
            <li element-size="medium">
              <span class="column_valor negrito">{{ item.valor | preco }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div container-wrapper class="cardFooterControls">
      <div container-size="small" class="actions left"></div>
      <div *ngIf="!isFluxoAprovacao" class="right reprovado">
        <h1>VERBA EXTRA REPROVADA</h1>
      </div>
      <div
        id="btn{{ item.identificador }}"
        class="actions"
        *ngIf="isFluxoAprovacao"
      >
        <button
          class="secondary-action-red"
          (click)="onReapprovalVerbaExtra(item)"
          type="button"
        >
          Reprovar
        </button>
        <button
          class="main-action"
          (click)="onApprovalVerbaExtra(item)"
          type="button"
        >
          Aprovar
        </button>
      </div>
      <div
        id="btn-reprovar{{ item.identificador }}"
        class="actions"
        *ngIf="item?.status === 'REPROVADO_NVL2'"
      >
        <button
          class="secondary-action-gray"
          (click)="onCancelVerbaExtra(item)"
          mat-stroked-button
          type="button"
        >
          Cancelar
        </button>
        <button
          class="secondary-action-red-filled"
          (click)="onSendReprovalVerbaExtra(item)"
          type="button"
          [disabled]="
            !item.motivo ||
            item.motivo.trim().length === 0 ||
            !item.idMotivoReprovacao ||
            (item.idMotivoReprovacao == 1 &&
              item.arquivosReprovados.length == 0)
          "
        >
          Enviar
        </button>
      </div>
    </div>
  </ng-container>
</div>
